<template>
    <article class="border border-dark rounded p-3 my-2">
        <div class="article-title">
            <h4 class="mb-2"><div class="badge badge-success mr-1">{{capitalizeR(post.categoria)}}</div> <div class="badge badge-light">Publicado: {{formatDate(post.fecha)}}</div></h4>
            <h2 class="mb-2">{{post.titulo}}</h2>
        </div>
        <img class="mb-3" :src="post.imagen">
        <div class="content" v-html="post.contenido"></div>
    </article>
</template>

<script>
import { capitalize } from "@/helpers";
export default {
    name:"VerPreguntas",
    props:{post:Object},
    methods: {
      formatDate(rawDate){
      let date = new Date(rawDate)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()

      if(month < 10){
        return (`${day}-0${month}-${year}`)
      }else{
        return (`${day}-${month}-${year}`)
      }
    },
    capitalizeR(string){return capitalize(string)}
    }
}
</script>

<style>
article img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 6px;
}

/* ckeditor5-font/theme/fontsize.css */
.content h4 {
    font-size: .7em;
}
/* ckeditor5-font/theme/fontsize.css */
.content h3 {
    font-size: .85em;
}
/* ckeditor5-font/theme/fontsize.css */
.content h2{
    font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.content h1 {
    font-size: 1.8em;
}
/* ckeditor5-image/theme/image.css */
.content img {
    display: block;
margin: 0 auto;
max-width: 100%;
min-width: 100%;
height: 100%;
}

/* ckeditor5-block-quote/theme/blockquote.css */
.content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}

/* ckeditor5-list/theme/todolist.css */
.content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc( var(--ck-todo-list-checkmark-size) / 3 );
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
    transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}
/* ckeditor5-language/theme/language.css */
.content span[lang] {
    font-style: italic;
}
/* ckeditor5-code-block/theme/codeblock.css */
.content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.content .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}
@media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .content .page-break {
        padding: 0;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .content .page-break::after {
        display: none;
    }
}
</style>