<template>
    <div class='text-right'>
        <button class="btn btn-light btn-md" @click.prevent="$emit('cerrar',false)"><svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Regresar</button>
    </div>
</template>

<script>
export default {
    name:'ReturnButton',
    props:{align:String}
}
</script>