export function capitalize(word) {
    if (typeof word === 'string') {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    
    return word
}

/**
 * Define propiedades a objetos en caso de no existir, acorta las lineas al crear estas nuevas propiedades,
 * evitanto los parametros writable, enmumerable y configurable
 * @param obj objeto al cual se le agrega la propiedad
 * @param name nombre de la nueva propiedad
 * @param val valor que se setea en la propiedad
 */
export function setProp(obj,name,val){
    if (!Object.prototype.hasOwnProperty.call(obj, name)) {
        Object.defineProperty(obj, name, {
        value: val,
        writable: true,
        enumerable: true,
        configurable: true
        });
    }
}

/**
 * Comprueba que el valor tenga valor y no sea null
 * @param property de un objeto
 * @returns true si es vacio
 */
export function isEmpty(property){
    return property == null || property == '';
}
/**
 * Comprueba que la cadena dada no contenga nada mas que números
 * @param property un string a comprobar
 * @returns true si es numerico
 */
export function isNum(property){
    return /^\d+$/.test(property);
}

export function getRandomId(){
    let chars = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','p','q','r','s','t','u','v','w','x','y','z']
    return Math.floor(Math.random() * 999)+chars[Math.floor(Math.random() * 23)]+Math.floor(Math.random() * 999)+chars[Math.floor(Math.random() * 23)]
}

import firebase from "firebase/app";
import 'firebase/storage';

export function almacenarImagen(currentFile){
    return new Promise((resolve,reject)=>{
        // File or Blob
        var file = currentFile;
        // Upload file and metadata to the object 'images/mountains.jpg'
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child('images/' + file.name).put(file, {contentType:'image/jpeg'});
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        // Snapshoot actualiza la carga, on lo hace pero snapshoot tiene la data
        function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
        }, function(error) {
            alert("Se produjo un error, actualiza la pagina (tecla: F5), reintenta o comunicate con sistemas");
            switch (error.code) {
                case 'storage/unauthorized':
                    console.log("El usuario no tiene permisos para subir a firestore");
                    reject(error.code)
                    break;
                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    console.log("error desconocido");
                    console.log(error.serverResponse);
                    reject(error.code)
                break;
            }
        }, function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            });
        });
    })

}

export function sliceWithEllipsis(str,length) {
    if (str.length <= length) return str

    let arr = str.split(' ');
    let finalStr = '';
    
    arr.some(el => {
        if(((el+finalStr).length < length -3)) finalStr += " " + el;
        // finalStr += ((el+finalStr).length < length) ? " " + el : '...';
        return finalStr.length >= length -3;
    })
    // finalStr+='...';
    return finalStr.slice(1) + '...';
}
