<template>
    <div class="priority-group">
        <!-- <div class="priority-label">{{(ticket.priority!=undefined&&ticket.priority!=null) ? ticket.priority : 5}}</div> -->
        <select class="priority-select" v-model="priority">
            <option :value="i" v-for="i in 5" :key="i">{{i}}</option>
        </select>
        <button class="priority-button" @click.prevent="submit($event)">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"> <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/> </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
        </button>
    </div>
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
const fireMantenimiento = firebase.apps[1];
export default {
    props: {
		ticket:Object,
        id:String
    },
	name: 'SetTicketPriority',
    data() {
        return {
			priority:null,
        }
    },
	mounted() {
		this.priority = (this.ticket.priority != undefined || this.ticket.priority != null) ? this.ticket.priority : 5;
	},

    methods: {
		submit(event){
			// Se actualiza la vista para mostrar es estado de carga
            event.target.innerHTML = `
                <div class="spinner-border" style="width:14px;height:14px" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            `
			event.target.disabled = true;
            for (let input of document.querySelectorAll('form input,form select')) {
                input.disabled = true;
            }

			if(this.priority == this.ticket.priority) this.$emit('end',false);
            // /*eslint-disable*/
            // this.ticket['priority'] = this.priority;
            // /*eslint-enable*/

            let withoutReference = JSON.parse(JSON.stringify(this.ticket));
            withoutReference['priority'] = this.priority;


            // console.log(withoutReference);

			fireMantenimiento.database().ref(`tickets/${this.id}`).update(withoutReference).then(()=>{
				this.$emit('end',true);
			});

		},
    },
    
}

</script>

<style scoped>
.form-control {
	border: 1px solid black;
	border-radius: 3px;
}
.form-control:focus-within {
	color: black;
}
.form-control:focus-visible {
	color: black;
}

.priority-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: #ededed;
    border-radius: 3px;
    padding: 2px;
    width:90%
}
.priority-group > * {
    padding: 0.35rem 0.75rem;
}

.priority-select {
    font-size: 12px;
    padding: 2px;
    border-radius: 3px;
    width: 56%;
    color: #23272b;
}

.priority-button {
    border: none;
    width: 40%;
    background: #10b759;
    border-radius: 3px;
    color: #fff;
    padding: 0.46rem .75rem;
}
</style>