<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">
              {{ descripcion }}
              </p>
              <button class="btn btn-success w-25" v-if="empleados!=null && empleados.length!=0" @click="modalAsignar=true">Asignar nuevo locker</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <lista-carga v-if="triggers.load"></lista-carga>
    <!-- Lockers data table -->
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <table id="lockers" class="table">
              <thead>
                <tr>
                  <th><span class="text-muted">Fecha/Hora</span></th>
                  <th><span class="text-muted">Legajo</span></th>
                  <th><span class="text-muted">DNI</span></th>
                  <th><span class="text-muted">Nombre</span></th>
                  <th><span class="text-muted">Locker</span></th>
                  <th><span class="text-muted">Acción</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="locker in lockers" v-bind:key="locker.dni" >
                  <td>{{ locker.fecha }}</td>
                  <td>{{ this.empleados[locker.dni].leg_numero }}</td>
                  <td>{{ locker.dni }}</td>
                  <td>{{ locker.nombre }}</td>
                  <td>{{ locker.locker }}</td>
                  <td>
                      <button v-if="isEditor('Lockers')==true" class="btn btn-sm btn-success" @click="editarLocker(locker)">Editar</button>
                      <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Editar</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal-asignar
    v-if="modalAsignar"
    :empleados="empleados"
    :lockers="lockers"
    @cerrar-modal-asignar="refrescarLockers($event)"
  >
  </modal-asignar>

  <modal-editar
    v-if="modalEditar"
    :empleados="empleados"
    :lockers="lockers"
    :lockerEditable="lockerEditable"
    @cerrar-modal-editar="refrescarLockers($event)"
  >
  </modal-editar>
</template>

<script>
import axios from "axios";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");
import ModalAsignar from './ModalAsignar';
import ListaCarga from '../ListaCarga.vue';
import ModalEditar from './ModalEditar.vue';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
export default {
  name: "Lockers",
  components: {ModalAsignar,ListaCarga, ModalEditar},
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Lockers",
    descripcion: "Distribución actual de los lockers",
    lockers: [],
    empleados: [],
    showModal: false,
    modalAsignar: false,
    modalEditar: false,
    lockerEditable: [],
    triggers: {
      load: true,
    }
  }),
  methods: {
    isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
    obtenerEmpleadosYLockers(){
      this.triggers.load = true;
        axios
        .get( "https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty")
        .then((response) => {
            this.empleados = response.data;
            axios
            .get("https://aplicativos.firebaseio.com/lockers.json?print=pretty")
            .then((response) => {
              // this.lockers = response.data;

              const lockerData = response.data;

              Object.keys(lockerData).map((lockerId)=> {
                  let locker = {
                    id: lockerId,
                    nombre: lockerData[lockerId].nombre,
                    dni: lockerData[lockerId].dni,
                    fecha: lockerData[lockerId].fecha,
                    locker: lockerData[lockerId].locker,
                  }
                  this.lockers.push(locker);
              });

              setTimeout(() => {
                 $("#lockers").DataTable({
                    dom: 'Bfrtip',
                    responsive: true,
                    nowrap : false,
                    buttons: ["copy", "excel", "csv", "pdf"],
                });
              }, 400);
              this.triggers.load = false;
            })
        }).catch(function (error) {
          console.log('Show error notification!');
            return Promise.reject(error);
        });
    },
    refrescarLockers(event){
      this.modalAsignar = false;
      this.modalEditar = false;
      if (event == true) {
        $('#lockers').dataTable().fnDestroy();
        this.obtenerEmpleadosYLockers();
      }
    },
    editarLocker(locker){
      this.lockerEditable = locker;
      this.modalEditar = true;
    }
  },
  mounted() {
    this.obtenerEmpleadosYLockers();
  },
};
</script>
