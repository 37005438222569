<template>
    <div class="row" v-if="trigger.loading">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3">Creando un nueva pregunta frecuente</h3>
                <!-- <div style="height: 4px" class="progress">
                    <div role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 40%" class="progress-bar" ref="progressBar"></div>
                </div> -->
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="trigger.complete">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3 text-success">¡Buen trabajo!</h3>
                <h4 class="text-center mb-3"><div class="badge badge-success">Pregunta agregada</div></h4>
            </div>
        </div>
    </div>
    <div class="row" v-if="trigger.edition">
      <div class="col">
          <h4 class="mb-2">Crear un nueva pregunta frecuente</h4>
          <div class="form-row mb-2">
              <div class="col mb-2">
                <div class="form-label">Preunta</div>
                <input type="text" class="form-control" v-model="post.titulo" @change="error.titulo = ''">
                <div class="text-danger mt-1" v-if="error.titulo">&otimes; {{error.titulo}}</div>
              </div>
             
          </div>
          
          <div class="form-label">Respuesta</div>
          <div class="text-danger mb-1" v-if="error.contenido">&otimes; {{error.contenido}}</div>
          <editor @change="setPostContent"></editor>
      </div>
      <div class="col-12 text-right">
          <button type="button" class="btn btn-success" @click.prevent="crear()">Guardar Pregunta frecuente</button>
      </div>
  </div>
</template>

<script>
import Editor from '@/components/admin/Editor'
import ImageSelect from '@/components/admin/ImageSelect'

import firebase from "firebase/app";
import "firebase/database";


import { capitalize,almacenarImagen } from "@/helpers.js";

export default {
    name:"CrearPreguntas",
    components: {Editor},
    data(){
        return {
            categorias: [
                'RRHH','Información','Novedad'
            ],
            post: {
                titulo:'',
                categoria: 'Novedad',
                contenido: '',
                delta: '',
                imagen: '',
                resumen:''
            },
            error: {
                titulo:'',
                categoria: '',
                contenido: '',
                delta: '',
                imagen: '',
                resumen:''
            },
            trigger:{
                edition:true,
                loading:false,
                complete:false,
            }
        }
    },
    methods:{
        capitalizeR(string){return capitalize(string)},
        setPostContent(data){
            this.post.contenido = data.html;
            this.post.delta = data.delta;
        },
        setPostImage(image){
            this.post.imagen = image
            this.error.imagen = ''
        },
        crear(){
            // Es válido hasta que se demuestre lo contrario
            let valido = true;
         

            if (!valido) return null

            this.trigger.edition = false
            this.trigger.loading = true
            // Es válido y se prosigue con la carga
          
               
                this.post.fecha = Date.now()
                firebase.database().ref('/preguntas').push(this.post).then(()=>{
                    this.trigger.loading = false;
                    this.trigger.complete = true;
                    setTimeout(() => {
                        this.$emit('cerrar',true)
                    }, 1500);
                })
            

        },

    },
    mounted(){
    }
}
</script>

<style>
/* .progress-bar {
    position:relative;
    transition: all .2s linear
} */

/* Loading spinner */
.spinner {
  margin: 20px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #3f4df1a9;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>