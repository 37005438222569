<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header mb-1 px-0 align-items-center no-gutters" v-if="!success">
                            <div class="col-10"></div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <div class="modal-body m-0" v-if="success">
                            <h4 class="text-success text-center mt-1">✓ Enviado a legales</h4>
                        </div>
                        <div class="modal-body p-0" v-if="!success">
                            <div class="alert alert-secondary mb-4" role="alert">
                                Estas a punto de enviar a legales a <mark>{{ nombreEmpleado + " " + apellidoEmpleado }}</mark>
                            </div>
                            <!-- <p class="mb-3 text-center"></p> -->
                            <!-- Button Add -->
                            <div class="w-100 text-right">
                                <button
                                    type="button"
                                    @click.prevent="close()"
                                    class="btn btn-light"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger ml-2"
                                    @click="enviarALegales()"
                                >
                                    Enviar a legales
                                </button>   
                            </div>
                                            
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import axios from "axios";

export default {
    name: "ModalLegales",
    props: ['dniEmpleado','nombreEmpleado','apellidoEmpleado','legajoEmpleado'],
    data(){
        return {
            success: false,
            seEnvio: false,
        }
    },
    mounted(){
        // console.log(this.dniEmpleado + " " + this.nombreEmpleado);


        
    },
    methods: {
        enviarALegales(){

            this.removerDeOperacion();

            firebase.database().ref('legales/'+this.dniEmpleado).push({       
                nombre:this.nombreEmpleado + " " + this.apellidoEmpleado,
                status:'Sin Resolver'
            })
            .then(()=>{
                this.success = true;
                this.seEnvio = true;
                setTimeout(() => {
                    this.close();
                }, 1000);
            });
        },
        close(){
            this.$emit('cerrar-modal-legales',this.seEnvio);
        },
        removerDeOperacion(){
            axios.get("https://aplicativos.firebaseio.com/distribucion.json?print=pretty")
            .then(response=>{
                const distribuciones = response.data;
                // let fireId = null;
                Object.keys(distribuciones).forEach(idEmpresa => {
                    Object.keys(distribuciones[idEmpresa]).forEach((empleado)=>{
                        if (distribuciones[idEmpresa][empleado].legajo == this.legajoEmpleado) {
                            firebase.database().ref("distribucion/" + idEmpresa + "/" + empleado).remove().then(()=>{
                                console.log("removido de la distribucion: " + idEmpresa);
                                return null
                            })
                        }
                    })
                })
            })
        }
        
    }
}
</script>

<style scoped>
.success-message {
    text-align: center;
    color: #1ea21e;
}

mark {
    background: none;
    text-decoration: underline red 3px;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 36%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-header {
    border-bottom: none !important;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>