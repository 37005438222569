<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <p class="card-description">
              {{ descripcion }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div id="content" class="flex ">
        <div class="padding d-flex">
          <div class="page-content page-container" id="page-content">
            <div class="padding">
              <table>
                <tr>
                  <td><input type="text" id="dni" class="form-control" v-model="dni" placeholder="Busca por DNI ..."></td>
                  <td><input type="text" id="cuit" class="form-control" v-model="cuit" placeholder="Busca por DNI ..."></td>
                </tr>
                <tr>
                  <td>
                    <button type="button" style="width: 300px;height: 54px;" class="btn w-sm mb-3 btn-success" @click="buscadni()">BUSCAR SI FUE EMPLEADO DE FOOD SERVICE</button>
                  </td>
                  <td>
                    <button type="button" style="width: 300px;height: 54px;" class="btn w-sm mb-3 btn-success" @click="buscacuit()">BUSCAR POR DNI EN BASE DE DATOS DE PERSONAS</button>
                  </td>
                </tr>
              </table>
              <p/>
            </div>  
            <div class="col-sm-12">
              <p>Resultados de busqueda</p>
              <div class="list list-row block" id="resultados"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import axios from "axios";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");

export default {
  name: "Prospecto",
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Administrador de Prospectos",
    descripcion: "Realiza busquedas y analisis por dni, cuit o nombre",
    legajos: [],
    dni: '',
    cuit: '',

  }),
  methods: {
    buscadni: function (){

      let vm = this;

      $("#resultados").html('');    

      axios.post("http://808107d2d15a.sn.mynetname.net:8547/api-int/public/api/auth/login",
        {
          "email":"admin@foodservice.com.ar",
          "password":"adminadmin"
        }
      ).then(function (response) {

        let url = "http://808107d2d15a.sn.mynetname.net:8547/api-int/public/api/nomina/"+vm.dni;

        axios.get(url, {
            headers: {
              'Authorization': 'Bearer '+response.access_token,
              'Access-Control-Allow-Origin': "http://808107d2d15a.sn.mynetname.net:8547"
            },
        }).then(function (datos) {
              
          let totaln = datos.data.length;

          if (totaln == 0 || totaln == undefined) {
              $("#resultados").prepend("<div style='padding: 10px 0px 0px 10px;'>El DNI ingresado no figura en la nomina de Food Service America</div><br><hr>");
          }else{

            if (datos.leg_fecegr!=null) {
                          
              $("#resultados").prepend("<div style='padding: 10px 0px 0px 10px;'>El DNI ingresado pertenece a "+datos.leg_nombre+" "+datos.leg_apellido+" ya fue empleado en  Food Service America. <br> Su fecha de ingreso fue el "+datos.leg_fecingr.replace('00:00:00','')+" y fue desvinculado el dia "+datos.leg_fecegr.replace('00:00:00','')+"</div><br><hr>");
            }else{

              $("#resultados").prepend("<div style='padding: 10px 0px 0px 10px;'>El DNI ingresado aún pertenece a la nomina de Food Service America</div><br><hr>");

            }
          }  
        });
      });

      setTimeout(function(){
        $('#dni').val('');
      },2000);
    },
    buscacuit: function (){

      let vm = this;

      $("#resultados").html('');


      let url = "https://sayannie.com/api.php?dni="+vm.cuit;

      try {
        axios.get(url)
        .then(function (datos) {

          let res = datos.data;
 
          if (datos.data[0].dni!=null) {

            $("#resultados").prepend("<div style='padding: 10px 0px 0px 10px;'>El DNI ingresado pertenece al cuil "+res[0].dni+", perteneciente a "+res[0].nombre+"</div><br><hr>");
          }else{

            $("#resultados").prepend("<div style='padding: 10px 0px 0px 10px;'>El DNI ingresado no figura en ninguna base de datos de personas</div><br><hr>");

          }
        });  

      } catch(err) {
        console.log(err);
      }
    }  
  },
  mounted() {
    //let vm = this;


  }
}
</script>
