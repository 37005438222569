<template>
    <lista-carga v-if="trigger.load" :card="false"></lista-carga>
    <div v-else>
      <h4 class="table-title">Listado de posts frecuentes</h4>
      <table class="table" id="tableposts">
      <thead>
        <tr>
          
          <th>posts</th>
         
          <th>Respuesta</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="posts in posts" :key="posts">
         
          <td>{{posts.titulo}}</td>
        
          <td>{{posts.resumen}}</td>
          <td>
            <button class="btn btn-primary mr-2" @click.prevent="$emit('mostrar',posts)">Vista previa</button>
            <button class="btn btn-warning" @click.prevent="$emit('editar',posts)">Editar</button>
          </td>
        </tr>
        
      </tbody>
    </table>
    </div>
</template>

<script>
// import 'jquery/dist/jquery.min.js';
//Datatable Modules

import $ from 'jquery'; 
// import axios from 'axios';

import ListaCarga from "@/components/admin/ListaCarga.vue";

import { tableConfig } from "@/env.js";
import { capitalize } from "@/helpers.js";

export default {
  name:'Listarposts',
  components: {ListaCarga},
  props:{posts:Object},
  data() {
    return {
      trigger:{load:true}
    }
  },
  methods: {
    formatDate(rawDate){
      let date = new Date(rawDate)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()

      if(month < 10){
        return (`${day}-0${month}-${year}`)
      }else{
        return (`${day}-${month}-${year}`)
      }
    },
    capitalizeR(string){return capitalize(string)}
  },
  mounted(){
    setTimeout(() => {
      this.trigger.load = false
      $('#tableposts').dataTable().fnDestroy();
      this.$nextTick(()=>{
        $('#tableposts').dataTable(tableConfig);
      })
    }, 1500);
  },
};
</script>

<style>
.rounded-circle {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
</style>