<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <p class="card-description">
              {{ descripcion }}
            </p>

            <h6 class="card-title mt-4">Selecciona las fechas y planta a consultar</h6>
             <div class="row">
                  <div class="col-8">
                    <form>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Desde</label>
                                <input type="date" class="form-control mb-2 mr-sm-2" id="desdeasist" v-model='fdesde' @change="chequearRangoFecha()"/>
                                <span class="text-danger" v-if="fdesde == '' || fdesde == null">· La fecha de inicio es obligatoria</span>
                            </div>
                            <div class="col">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                                <input type="date" class="form-control mb-2 mr-sm-2" id="hastaasist" v-model='fhasta' @change="chequearRangoFecha()"/>
                            </div>
                            <div class="col-12" v-if="alert.tiempo.length">
                              <div class="alert alert-warning">{{alert.tiempo}}</div>
                            </div>
                        </div>
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Zona seleccionada</label>
                        <select class="custom-select mr-sm-2" id="zona" v-model="zonaSeleccionada">
                            <option v-for="zona in zonas" :key="zona.codigo" :value="zona.codigo" :selected="zona.codigo == '000001'">
                                {{zona.nombre}}
                            </option>
                        </select>
                        <div class="text-right mt-3">
                            <button @click.prevent="consulta('')" class="btn btn-md btn-success">Consultar presentes</button>
                        </div>
                    </form>
                  </div>
                  <div class="col-4">
                      <div class="border border-light rounded p-3 h-100">
                        <h6 class="my-2 text-uppercase">Vista rápida</h6>
                        <p v-if="(fdesdeVista && fhastaVista) && (fdesdeVista != fhastaVista)">Desde el día <span class="badge badge-light">{{fdesdeVista}}</span> hasta el <span class="badge badge-light">{{fhastaVista}}</span></p>
                        <p v-else-if="(fdesdeVista == fhastaVista)">Del día <span class="badge badge-light">{{fdesdeVista}}</span></p>
                        <p v-else-if="fdesdeVista">Desde el la fecha <span class="badge badge-light">{{fdesdeVista}}</span></p>
                        <p v-else>Ninguna fecha seleccionada</p>
                        <hr/>
                        <div class="">
                          <div class="row mb-1">
                              <div class="col-9">
                                  Dentro de convenio
                              </div>
                              <div class="col-3 text-right">
                                  <div class="badge badge-light" v-html="convenio1"></div>
                              </div>
                          </div>

                          <div class="row mb-1">
                              <div class="col-9">
                                  Fuera de convenio
                              </div>
                              <div class="col-3 text-right">
                                  <div class="badge badge-light" v-html="conveniono1"></div>
                              </div>
                          </div>
                          
                          <div class="row mb-1">
                              <div class="col-9">
                                  Total
                              </div>
                              <div class="col-3 text-right">
                                 <div class="badge badge-light" v-html="total1"></div>
                              </div>
                          </div>
                      </div>
                       
                      
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="errors.fdesdeInvalida">
      <div class="col">
        <div class="alert alert-danger">
          La fecha de inicio es obligatoria, introducila para poder hacer una búsqueda.
        </div>
      </div>
    </div>

    <lista-carga v-if="load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <div class="table-responsive">
                <table id="asistencias" class="table">
                  <thead>
                    <tr>
                        <th><span class="text-muted">Legajo</span></th>
                        <!-- <th><span class="text-muted">Status</span></th> -->
                        <th><span class="text-muted">Nombre</span></th>
                        <th><span class="text-muted">Categoria</span></th>
                        <th><span class="text-muted">Sector</span></th>
                        <th><span class="text-muted text-wrap">Fecha Entrada</span></th>
                        <th><span class="text-muted text-wrap">Fecha Salida</span></th>
                        <th><span class="text-muted text-wrap">Hora Entrada</span></th>
                        <th><span class="text-muted text-wrap">Hora Salida</span></th>
                        <th><span class="text-muted text-wrap">Total Horas</span></th>
                        <th><span class="text-muted">Zona</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in asistencias" v-bind:key="key">
                        <td class="text-wrap">{{value.legajo}}</td>
                        <!-- <td class="text-wrap"><div v-html="value.status"></div></td> -->
                        <td class="text-wrap">
                            <span class="item-amount d-none d-sm-block text-sm text-wrap">
                            {{value.nombre}}    
                            </span>
                        </td>  
                        <td class="text-wrap">{{value.categoria}}</td>
                        <td class="text-wrap">{{value.sector}}</td>
                        <td class="text-wrap">{{value.fechaEntrada}}</td>
                        <td class="text-wrap">{{value.fechaSalida}}</td>
                        <td class="text-wrap">{{value.horaEntrada}}</td>
                        <td class="text-wrap">{{value.horaSalida}}</td>
                        <td class="text-wrap">{{value.total}}</td>
                        <td class="text-wrap">{{value.operacion}}</td>
                    </tr>  
                  </tbody>
                </table>
              </div>
            <!-- <div class="flex float-right"></div>
              <button @click="mapa()" class="btn w-sm mb-1 btn-sm btn-primary">
                Ver Mapa en Tiempo real
              </button>
            </div> -->
        </div>
      </div>
    </div>
    
  </div>
  </div>
</template>

<script>

import axios from "axios";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
import moment from 'moment';
import ListaCarga from '../ListaCarga';

import config from './config'
import DTConfig from '@/dataTableConfig.js'


export default {
  name: "Presentismo",
  components: { ListaCarga },
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Personal",
    descripcion: "Podés seleccionar una fecha específica y una planta para visualizar la lista del personal activo.",
    datos: [],
    categorias: [],
    fdesde: null,
    fhasta: null,
    total1: 0,
    conveniono1: 0,
    convenio1: 0,
    zonas: [],
    personal: [],
    asistencias: [],
    load: true,
    zonaSeleccionada:"000001",
    mesActual: null,
    alert:{
      tiempo:'',
    },
    errors: {
      fdesdeInvalida: false,
    }
  }),
  methods: {
    pad: function(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    llena: function(number, len) {
      return "0".repeat(len - number.toString().length) + number.toString();
    },
    obtenerPersonalConCategorias(){
        let vm = this;
        axios
        .get("https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty")
        .then((response) => {
            $.each(response.data, function(i, item) {
                if (item.leg_numdoc > 0){
                    vm.personal[item.leg_numdoc] = item.cat_codigo;
                }
            });    

            axios
            .get("https://aplicativos.firebaseio.com/categorias.json?print=pretty")
            .then((response) => {
                $.each(response.data, function(i, item) {
                    vm.categorias[item.codigo] = item.nombre;
                });
            });
        });
    },
    fechaActual(){
        let today = new Date();
          let m = today.getMonth() + 1;
          let mes = (m < 10) ? '0' + m : m;
          let dia = today.getDate();
          dia = (dia < 10) ? '0' + dia : dia;
          return today.getFullYear() + '-' + mes + '-' + dia;
    },
    chequearRangoFecha(){
      this.alert.tiempo = '';
      this.errors.fdesdeInvalida = false;
      let mesHasta;

      if (this.fhasta) {
        mesHasta = this.fhasta.split('-')[1];
      } else {
        mesHasta = this.mesActual;
      }

      let mesDesde = this.fdesde.split('-')[1];

      if ((mesHasta - mesDesde) > 0 && (mesHasta - mesDesde) <= 2) {
        this.alert.tiempo = 'Atención la consulta puede demorar algunos segundos';
      }else if((mesHasta - mesDesde) > 2){
        this.alert.tiempo = 'Atención la consulta puede demorar más de 10 segundos'
      }
    },
    reemplazarIndefinido(string) {
        return (string==undefined || string == null || string == '') ? '-' : string;
    },
    consulta: function( origin ) {
      this.fdesdeVista = this.fdesde;
      this.fhastaVista = this.fhasta;

      if (this.fdesde == '' || this.fdesde == null) {
        this.errors.fdesdeInvalida = true;
        return null
      }

      // Se activa trigger para mostrar loader placeholder
      this.load = true;

      console.log(`desde: ${this.fdesde} hasta: ${this.fhasta}`);

      // Si no se selecciono fecha de hasta, se setea la fecha actual
      if (this.fhasta == null) {
        this.fhasta = new Date().toISOString().split('T')[0];
      }

      this.asistencias = [];
      this.total1 = 0;
      this.convenio1 = 0;
      this.conveniono1 = 0;
      
      let url = `https://auth.foodservice.com.ar/?type=presentismo&desde=${this.fdesde}&hasta=${this.fhasta}&access_token=1234567`;
      console.log(url);

      axios
      .get(url)
      .then(response => {
        let presentes = response.data;

        this.asistencias = presentes.flatMap(presente => {
          // console.log(presente.nombre + ' ' + presente.fechaEntrada);

          if (presente.zona == '2021' && this.zonaSeleccionada == "000001") {
            presente.zona = '000001';
          }

          // Se comprueba si es la operación buscada
          //Se utiliza la propiedad de flatMap de retornar [] para saltear el indice iterado
          if (presente.zona != this.zonaSeleccionada) return []

          
          let total = 0;
          let fsalida = (presente.fechaSalida!=null) ? presente.fechaSalida : '-';
          let hsalida = (presente.horaSalida!=null) ? presente.horaSalida : '-';

          // console.log(`${presente.nombre} salio: ${fsalida} a las ${hsalida}`);

          // Se setean las horas trabajadas
          if (presente.fechaSalida!=null) {
            let nfe = presente.fechaEntrada;
            let nfs = presente.fechaSalida;
          
            let __startTime = moment(nfe+'T'+presente.horaEntrada).format();
            let __endTime = moment(nfs+'T'+presente.horaSalida).format();
  
            let __duration = moment.duration(moment(__endTime).diff(__startTime));
          
            let horas = __duration._data.hours;
            let minutos = __duration._data.minutes;
            let segundos = __duration._data.seconds;
  
            total = this.pad(horas,2)+':'+this.pad(minutos,2)+':'+this.pad(segundos,2);
          }

          // console.log(`${presente.nombre} salio: ${fsalida} a las ${hsalida} y trabajo ${total}`);
          
          // Se cuentan fuera de convenio y totales
          if (presente.categoria == 'FUERA DE CONVENIO') {
            // console.log(`${presente.nombre} está fuera de convenio`);
            this.conveniono1++
          }else { this.convenio1++ }

          return {
            'dni': this.reemplazarIndefinido(presente.dni),
            'legajo': presente.legajo,
            'nombre': presente.nombre,
            'categoria': presente.categoria,
            'sector':this.reemplazarIndefinido(presente.sector),
            'fechaEntrada': presente.fechaEntrada,
            'fechaSalida': fsalida,
            'horaEntrada': presente.horaEntrada,
            'horaSalida': hsalida,
            'total': total,
            'operacion': presente.zona
          }
          // this.asistencias.push(newAsistencia);

        })

        this.total1 = this.convenio1+this.conveniono1;
        this.load = false;


        // $('#asistencias').dataTable().fnDestroy();

        // setTimeout(() => {
        //   }, 5000);

        this.$nextTick(() => {
          let config = DTConfig;
          config.bSort = true;
          config.buttons = [ 'copy', 'excel', 'csv', 'pdf' ];
          config.emptyTable = "No se registraron presentes en las fechas seleccionadas para la operación seleccionada";
          $('#asistencias').dataTable(DTConfig);
        })
        // setTimeout(() => {
        //     this.load = false;
        //     this.$nextTick(()=>{
        //         this.actualizarTabla();
        //     });
        // }, 500);





      })
      .catch(error => {
        console.log(error);
        alert("Lo sentimos, se produjo un error. Actualice la página (tecla F5) y reintente o comuníquese con sistemas");
      })

      
    },
  },
  mounted() {
    // this.zonaSeleccionada = ;
    this.fdesde = this.fechaActual();
    this.fdesdeVista = this.fdesde;
    this.mesActual = new Date().toISOString().split('T')[0].split('-')[1];

    this.zonas = config.zonas;

    this.consulta();
  },

};
</script>

<style scoped>
.form-control {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select {
    font-size: .8rem;
}
</style>