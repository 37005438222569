<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!triggers.success">
                            <div class="col-10">Agregá un nuevo ticket</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>

                        <div class="modal-body m-0" v-if="triggers.success">
                            <h5 class="text-success text-center mt-1">✓ Ticket creado</h5>
                        </div>

                        <div class="modal-body" v-if="!triggers.success">
                            <label for="selectEmpleado" class="form-label">Seleccioná un empleado</label>
                            <Select2 class="w-100 input-error" v-model="operacionSeleccionada" :options="listadoPersonalActivo" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @select="guardarSeleccion($event)"/>
                            <span class="text-danger" v-if="errors.dni">· Seleccioná un empleado</span>
                            

                            <div class="row mt-3">
                                <div class="col">
                                    <label for="novedad">¿Cual es la novedad reportada?</label>
                                    <select class="custom-select mb-1 resolucionSelect" v-model="ticket.novedad" @change="errors.novedad = false">
                                        <option value="null" selected disabled>Selecciona una novedad</option>
                                        <option v-for="(novedad,i) in novedades" :key="i" :value="novedad">{{novedad}}</option>
                                    </select>
                                    <span class="text-danger" v-if="errors.novedad">· Seleccioná una novedad</span>
                                </div>
                                <div class="col">
                                    <label for="novedad">Seleccioná el estado actual</label>
                                    <select class="custom-select mr-3 resolucionSelect" v-model="ticket.status">
                                        <option value="Sin resolver" selected>Sin resolver</option>
                                        <option value="En proceso">En proceso</option>
                                        <option value="Resuelto">Resuelto</option>
                                    </select>
                                </div>
                            </div>

                            <label class="form-label mt-2">Agregar una observacion</label>
                            <!-- Input -->
                            <textarea class="w-100 form-control mb-1" v-model="ticket.observacion" @change="errors.observacion = false" rows="3"></textarea>
                            <span class="text-danger" v-if="errors.observacion">· Es necesario detallar el ticket</span>

                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="mb-3 d-flex align-items-center">
                                        <input type="checkbox" class="" id="resolucionCheck" @change="triggers.showResolucion = !triggers.showResolucion">
                                        <label class="mb-0 pt-1 ml-2" for="resolucionCheck">Agregar resolución</label>
                                    </div>
                                </div>
                                <div class="col-12" v-if="triggers.showResolucion">
                                    <label class="form-label mt-2">Detallar la resolución</label>
                                    <!-- Input -->
                                    <textarea class="w-100 form-control mb-3" v-model="ticket.resolucion" rows="3"></textarea>
                                </div>
                            </div>
                            



                            
                                               
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!triggers.success">
                            <button
                                type="button"
                                @click.prevent="crearTicket()"
                                class="btn btn-success"
                            >
                                Crear ticket
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";
import Select2 from 'vue3-select2-component';

export default {
    components: {Select2},
    name: "ModalLegales",
    props: ['personal'],
    data(){
        return {
            listadoPersonalActivo: [],
            novedades: [
                "DUDA SUELDO",
                "ASIGNACIONES FAMILIARES",
                "OBRA SOCIAL",
                "HS EXTRAS",
                "AJUSTE",
                "CONSULTA OBRA SOCIAL",
                "CONSULTA OBRA SOCIAL MEDICUS",
                "CONSULTA POR VACACIONES ",
                "VACACIONES",
                "HORAS EXTRAS",
                "CONSULTA SUELDO",
                "ANTICIPO DE SUELDO",
                "CONSULTA VACACIONES",
                "CONSULTA VACACIONES LIQUIDADAS",
                "CONSULTA FERIADO",
            ],
            triggers: {
                showResolucion: false,
                success: false,
            },
            ticket : {
                dni:null,
                fecha:null,
                hora:null,
                novedad:null,
                observacion:null,
                resolucion:null,
                status: null
            },
            errors: {
                dni:false,
                novedad:false,
                observacion: false
            },
            seReasigno: false,
            
        }
    },
    mounted(){
        axios
        .get("https://aplicativos.firebaseio.com/legales.json?print=pretty")
        .then(legalResponse=>{
            const legales = legalResponse.data;
            /**
             *  Select2 requiere un array [{id:1,text:nombre}]
             * 	Se mapea el response a traves de las claves del objeto JSON
             */			
            this.listadoPersonalActivo = Object.keys(this.personal).flatMap((key)=>{
                if(legales[this.personal[key].leg_numdoc] != null) {
                    return []
                } else {
                    return {
                        id: this.personal[key].leg_numero,
                        text: this.personal[key].leg_apellido + " " + this.personal[key].leg_nombre,
                        dni: this.personal[key].leg_numdoc
                    }
                }
            });						
        })
    },
    methods: {
        guardarSeleccion({id, text, dni}){
            this.errors.dni = false;
            this.ticket.dni = dni;
        },
        close(){
            this.$emit('cerrar-modal-nuevo',this.seReasigno);
        },
        obtenerFecha(){
            return new Date().toISOString().slice(0, 10);
        },
        obtenerHora(){
            var time = new Date();
            let current = ("0" + time.getHours()).slice(-2) + ":";
            current +=  ("0" + time.getMinutes()).slice(-2);
            return current
        },
        crearTicket(){
            if (!this.formularioValido()) {
                return null;
            }

            firebase.database().ref('/orientacion-b').push({
                dni: this.ticket.dni,
                fecha: this.obtenerFecha(),
                hora: this.obtenerHora(),
                novedad: this.ticket.novedad,
                observacion: this.ticket.observacion,
                resolucion: this.ticket.resolucion,
                status:  this.ticket.status
            })
            .then(()=>{
                console.log("ticket creado");
                this.triggers.success = true;
                this.seReasigno = true;
                setTimeout(() => {
                    this.close();
                }, 1000);
            })
            .catch(error=>{
                if(error!=null){
                    alert("Hubo un error");
                }
            })
        },
        formularioValido(){
            let valido = true;
            if (this.ticket.observacion == null || this.ticket.observacion== "") {
                this.errors.observacion = true;
                valido = false;
            }
            if (this.ticket.novedad == null || this.ticket.novedad == "") {
                this.errors.novedad = true;
                valido = false;
            }
            if (this.ticket.dni == null) {
                this.errors.dni = true;
                valido = false;
            }
            return valido;
        }
        
    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 50%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>