<template>
	<div class="page-content">
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div> 
		<lista-carga v-if="trigger.loading"></lista-carga>
		<!-- Template content  -->
		<div class="row" v-if="!trigger.loading && !trigger.detalle">
			<div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-12 col-md-6 mb-3">
                                <h4 class="">{{listTitle}}</h4>
                            </div>
                            <div class="col-12 col-md-6 mb-3 d-flex align-items-center justify-content-end">
                                <div class="input-group w-50">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <svg xmlns="http://www.w3.org/2000/svg" style="padding-bottom:1px" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16"> <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/> </svg>
                                            &nbsp;Filtrar:
                                        </span>
                                    </div>
                                    <select class="form-control" v-model="selectedPriority" @change="filterByPriority($event)">
                                        <option :value="null" selected>Ordenar por fecha de creación</option>
                                        <option :value="i" v-for="i in 5" :key="i">Prioridad {{i}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="tickets" class="table" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Motivo</th>
                                        <th>Tipo</th>
                                        <th>Equipo</th>
                                        <th>Operación</th>
                                        <th>Responsable</th>
                                        <th>Estado</th>
                                        <th>Fecha</th>
                                        <th>Prioridad</th>
                                        <th></th>
                                        <th>Timestamp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(ticket,id) in filteredTickets" v-bind:key="id">
                                        <td>
                                            <div class="text-ellipsis">
                                                {{ticket.motivo}}
                                            </div> 
                                        </td>
                                        <td>{{ticket.tipo_motivo}}</td>
                                        <td>{{ticket.equipamiento}}</td>
                                        <td><div class="badge badge-light">{{ticket.operacion_nombre}}</div></td>
                                        <td><div class="badge badge-light">{{ticket.emisor_nombre}}</div></td>
                                        <td>
                                            <div class="badge badge-danger" v-if="(ticket.estado) == 'En espera'">{{ticket.estado}}</div>
                                            <div class="badge badge-warning" v-if="(ticket.estado) == 'En proceso'">{{ticket.estado}}</div>
                                            <div class="badge badge-success" v-if="(ticket.estado) == 'Resuelto'">{{ticket.estado}}</div>
                                        </td>
                                        <td>{{dateFormat(ticket.timestamp)}}</td>
                                        <td>
                                            <!-- <div class="badge badge-warning" style="font-size:14px;padding-bottom:2px">{{(ticket.priority!=undefined&&ticket.priority!=null) ? ticket.priority : 5}}</div>
                                            <div class="badge badge-primary" style="font-size:14px;padding-bottom:2px">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"> <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/> </svg>
                                            </div> -->

                                            <set-ticket-priority v-if="id==setPriority" :ticket="ticket" :id="id" @end="detalleEnd($event)"></set-ticket-priority>

                                            <div class="priority-group" v-else>
                                                <div class="priority-label">{{(ticket.priority!=undefined&&ticket.priority!=null) ? ticket.priority : 5}}</div>
                                                <button class="priority-button" @click.prevent="setPriority=id">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"> <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/> </svg>
                                                </button>
                                            </div>

                                            <!-- <div class="input-group mb-3">
                                                <input type="text" class="form-control" disabled :value="(ticket.priority!=undefined&&ticket.priority!=null) ? ticket.priority : 5">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16"> <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/> </svg>
                                                    </button>
                                                </div>
                                            </div> -->
                                        </td>
                                        <td>
                                            <button class="btn btn-primary btn-xs ml-2" style="font-size:13px" @click.prevent="handleDetalle({...ticket,...{fireId:id}})">
                                                <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:1px" width="13" height="13" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16"> <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/> <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/> </svg>
                                            
                                                Ver detalle
                                            </button>
                                        </td>
                                        <td>{{ticket.timestamp}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
			</div>
		</div>
        <div class="card" v-if="trigger.detalle">
            <div class="card-body">
		        <detalle-ticket :ticket="selected" @end="detalleEnd($event)"></detalle-ticket>
            </div>
        </div>
	</div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import firebase from "firebase/app";
import "firebase/database";
// import ModalAdministrar from './ModalAdministrar.vue';
// import ModalObservar from './ModalObservar.vue';
import ListaCarga from "../ListaCarga";
// import ModalZonal from './ModalZonal.vue';
import DetalleTicket from './DetalleTicket.vue';
import SetTicketPriority from './SetTicketPriority.vue';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    components: {
        ListaCarga ,
		DetalleTicket,
		SetTicketPriority
    },    
	name: 'Tickets',
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Tickets',
            descripcion: 'En este modulo usted podra visualizar y administrar los tickets de mantenimiento generados desde Consumos.',
            tickets:null,
            filteredTickets:null,
            selected:null,
            setPriority:null,
            selectedPriority:null,
            trigger:{detalle:false,loading:true},
        }
    },
    computed:{
        listTitle(){
            if(this.selectedPriority == null) return "Lista de tickets por fecha de creación";
            return "Tickets filtrados por prioridad: " + this.selectedPriority;
        }
    },
    methods: {
        clearFilter(){
            this.selectedPriority = null;
            this.filterByPriority();
        },
        filterByPriority(){
            this.filteredTickets = {};
            Object.keys(this.tickets).forEach((i)=>{
                let priority = (this.tickets[i].priority!=undefined) ? this.tickets[i].priority : 5;
                if(this.selectedPriority == null) {
                    this.filteredTickets[i] = this.tickets[i];
                }else if(priority == this.selectedPriority){
                    this.filteredTickets[i] = this.tickets[i];
                }
            });
            $('#tickets').dataTable().fnDestroy();
            this.$nextTick(()=>{
                $('#tickets').DataTable(tableConfig);
            });
            // console.log(JSON.parse(JSON.stringify(this.filteredTickets)));
        },
        isEditor(modulo){
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      	},
		handleDetalle(ticket){
            this.selected=ticket;
            this.trigger.detalle = true;
        },
        detalleEnd(update){
            this.selected=null;
            this.trigger.detalle = false;
            this.setPriority=null;

            if (update) {
                this.getTickets();
            }else {
                $('#tickets').dataTable().fnDestroy();
                this.$nextTick(()=>{
                    $('#tickets').DataTable(tableConfig);
                });
            }
        },
		async getTickets(){
			this.trigger.loading = true;

			// axios.get('https://fs-mantenimiento-dev-d4419-default-rtdb.firebaseio.com/tickets.json').then((response)=>{
			axios.get('https://fs-mantenimiento.firebaseio.com/tickets.json').then((response)=>{
                this.tickets = response.data;
                this.filteredTickets = response.data;
                this.trigger.loading = false;
                if(this.selectedPriority == null){
                    $('#tickets').dataTable().fnDestroy();
                    this.$nextTick(()=>{
                        $('#tickets').DataTable(tableConfig);
                    });
                }else {
                    this.filterByPriority();
                }
            }).catch((e) => {console.error(e);alert('Se produjo un error al obtener tickets')});
		},
        dateFormat(initDate,includeYear){
            let date = new Date(initDate)

            let day = date.getDate()
            let month = date.getMonth() + 1
            let year;
            if (includeYear==undefined) year = date.getFullYear()

            if(month < 10){
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-0${month}`) : (`0${day}-0${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-0${month}`) : (`${day}-0${month}-${year}`)
            }else{
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-${month}`) : (`0${day}-${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-${month}`) : (`${day}-${month}-${year}`)
            }
        }
    },
	async mounted() {
		this.getTickets();
    },
    
}


const tableConfig = {
    "language": {
    "decimal": ",",
    "thousands": ".",
    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
    "infoPostFix": "",
    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    "loadingRecords": "Cargando...",
    "lengthMenu": "Mostrar _MENU_ registros",
    "paginate": {
        "first": "Primero",
        "last": "Último",
        "next": "Siguiente",
        "previous": "Anterior"
    },
    "processing": "Procesando...",
    "search": "Buscar:",
    "searchPlaceholder": "Término de búsqueda",
    "zeroRecords": "No se encontraron resultados",
    "emptyTable": "Ningún dato disponible en esta tabla",
    "aria": {
        "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
        "sortDescending": ": Activar para ordenar la columna de manera descendente"
    },
    },
    "scrollX": true, 
    "search": {regex:true},
    dom: 'Bfrtip',
    responsive: true,
    nowrap: false,
    buttons: [
        'copy',
        'excel',
        'csv',
        'pdf'
    ],
    "order":[[9,'desc']],
    // "columnDefs": [
    //     {
    //         "targets": [ 0 ],
    //         "visible": false,
    //         "searchable": false
    //     }
    // ]
}

</script>

<style scoped>
/* .text-wrap {
    width: 300px;
    white-space: -moz-pre-wrap !important;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    white-space: pre-wrap;
    word-wrap: break-word;
    white-space: -webkit-pre-wrap;
    word-break: break-all;
    white-space: normal;
    hyphens: auto;

    background: #ececec;
    border-radius: 4px;
    padding: 0.4rem;
} */

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    width:242px
}

.priority-group {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background: #ededed;
    border-radius: 3px;
    padding: 2px;
    width:90%
}
.priority-group > * {
    padding: 0.35rem 0.75rem;
}

.priority-label {
    width: 56%;
    background: #f7f7f7;
    font-size: 0.98rem;
    border-radius: 3px;
    padding: 0.34rem .75rem;
}

.priority-button {
    border: none;
    width: 40%;
    background: #727cf5;
    border-radius: 3px;
    color: #fff;
}

.form-control {
	border: 1px solid black;
	border-radius: 3px;
    color: black;
}

.input-group-text {
    background: #727cf5;
    border: #727cf5;
    border-radius: 0.1875rem 0 0 0.1875rem;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 600;
}


</style>