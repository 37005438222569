<template>
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
          DASHBOARD
        </a>
        <div class="sidebar-toggler active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
      
        <ul class="nav">
          
          <li class="nav-item" v-if="isLector('DSH_Empresas')==true">
            <router-link :to="{ name: 'Empresas'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Operaciones</span>
            </router-link>
          </li>

         <!-- <li class="nav-item" v-if="isLector('Empresas')==true">
            <router-link :to="{ name: 'Sectores'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Sectores</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Empresas')==true">
            <router-link :to="{ name: 'Volantes'}" class="nav-link" >
              <i class="link-icon" data-feather="box"></i>
              <span class="link-title">Distribución de volantes</span>
            </router-link>
          </li>
        -->


           <li class="nav-item" v-if="isLector('DSH_Personal')==true">
            <router-link :to="{ name: 'Personal'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Zonales</span>
            </router-link>
          </li>
<!--
           <li class="nav-item" v-if="isLector('Lockers')==true">
            <router-link :to="{ name: 'Lockers'}" class="nav-link" >
              <i class="link-icon" data-feather="lock"></i>
              <span class="link-title">Lockers</span>
            </router-link>
          </li>


          <li class="nav-item" v-if="isLector('Bajas')==true">
            <router-link :to="{ name: 'Bajas'}" class="nav-link" >
              <i class="link-icon" data-feather="user-x"></i>
              <span class="link-title">Bajas de personal</span>
            </router-link>
          </li>

            <li class="nav-item" v-if="isLector('Orientacion')==true">
            <router-link :to="{ name: 'Orientacion'}" class="nav-link" >
              <i class="link-icon" data-feather="clipboard"></i>
              <span class="link-title">Orientación de personal</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('Novedades')==true">
            <router-link :to="{ name: 'Novedades'}" class="nav-link" >
              <i class="link-icon" data-feather="info"></i>
              <span class="link-title">Novedades operaciones</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Novedades')==true">
            <router-link :to="{ name: 'NovedadesHrbp'}" class="nav-link" >
              <i class="link-icon" data-feather="info"></i>
              <span class="link-title">Novedades HRBP</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Novedades')==true">
            <router-link :to="{ name: 'NovedadesBejerman'}" class="nav-link" >
              <i class="link-icon" data-feather="info"></i>
              <span class="link-title">Novedades Bejerman</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'Presentismo'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Presentismo</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Presentismo')==true">
            <router-link :to="{ name: 'MesPresente'}" class="nav-link" >
              <i class="link-icon" data-feather="check-circle"></i>
              <span class="link-title">Presentismo Mes</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('Legales')==true">
            <router-link :to="{ name: 'Legales'}" class="nav-link" >
              <i class="link-icon" data-feather="file"></i>
              <span class="link-title">Legales</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Liquidacion')==true">
            <router-link :to="{ name: 'Liquidacion'}" class="nav-link" >
              <i class="link-icon" data-feather="file"></i>
              <span class="link-title">Liquidación FS America</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Liquidacion')==true">
            <router-link :to="{ name: 'LiquidacionCat'}" class="nav-link" >
              <i class="link-icon" data-feather="file"></i>
              <span class="link-title">Liquidación FS Catering</span>
            </router-link>
          </li>



        <li class="nav-item" v-if="isLector('Liquidacion')==true">
            <router-link :to="{ name: 'AdelantoLiquidacion'}" class="nav-link" >
              <i class="link-icon" data-feather="file"></i>
              <span class="link-title">Adelantos FS America</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Liquidacion')==true">
            <router-link :to="{ name: 'AdelantoLiquidacionCat'}" class="nav-link" >
              <i class="link-icon" data-feather="file"></i>
              <span class="link-title">Adelantos FS Catering</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Permisos')==true">
            <router-link :to="{ name: 'Permisos'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Permisos</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Cartelera')==true">
            <router-link :to="{ name: 'Blog'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Cartelera | MF</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('Cartelera')==true">
            <router-link :to="{ name: 'Preguntas'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Preguntas Frecuentes | MF</span>
            </router-link>
          </li>

           <li class="nav-item" v-if="isLector('Novedades')==true">
            <router-link :to="{ name: 'Solicitudbjm'}" class="nav-link" >
              <i class="link-icon" data-feather="user"></i>
              <span class="link-title">Solicitud de cambio | MF</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('Mantenimiento')==true">
            <router-link :to="{ name: 'Mantenimiento'}" class="nav-link" >
              <i class="link-icon" data-feather="settings"></i>
              <span class="link-title">Mantenimiento</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isLector('Nomina')==true">
            <router-link :to="{ name: 'Nomina'}" class="nav-link" >
              <i class="link-icon" data-feather="users"></i>
              <span class="link-title">Nomina</span>
            </router-link>
          </li>
-->


<!--
            <li class="nav-item">
            <router-link :to="{ name: 'Prospecto'}" class="nav-link">
              <i class="link-icon" data-feather="help-circle"></i>
              <span class="link-title">Prospectos</span>
            </router-link>
          </li> -->


          

          


           
        
        </ul>
      </div>
    </nav>
</template>

<script>


const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));


export default {
  name: 'Menu',
    methods:{
     isLector(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      },
       mounted() {
       this.isLector('Empresas');
    },
  }
};



</script>