<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!success">
                            <div class="col-10">Asignar un nuevo locker</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <div class="modal-body m-0" v-if="success">
                            <h5 class="text-success text-center mt-1">{{successMensaje}}</h5>
                        </div>
                        <div class="modal-body" v-if="!success">
                            <form>
                                <div class="row">
                                    <div class="col-12">
                                        <label for="selectLocker" class="form-label">Selecciona un locker vacio</label>
                                        <input type="number" id="selectLocker" v-model="locker" @change="comprobarLockerPorNumero()" class="form-control" placeholder="100"/>
                                        <p 
                                            class="my-2" 
                                            :class="{'text-danger': !triggers.valido,  'text-success': triggers.valido}" 
                                            v-if="triggers.valido != null">
                                            {{triggers.validoMensaje}}
                                        </p>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
                            <button
                                type="button"
                                @click.prevent="vaciarLocker()"
                                class="btn btn-danger"
                            >
                                Vaciar locker
                            </button>
                            <button
                                type="button"
                                @click.prevent="editarLocker()"
                                class="btn btn-success"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
export default {
    name: "ModalEditar",
    props: ['empleados', 'lockers','lockerEditable'],
    data(){
        return {
            locker: this.lockerEditable.locker,
            triggers: {
                valido: null,
                validoMensaje: null,
            },
            seReasigno: false,
            success: false,
            successMensaje: null,
        }
    },
    mounted(){
        
    },
    methods: {
        comprobarLockerPorNumero(){
            if (this.locker == '') {
                this.triggers.valido = null;
                return null
            }

            this.locker = this.formatoCero(this.locker);

            let nombre = null;

            Object.keys(this.lockers).map(locker => {
                if(this.lockers[locker].locker == this.locker){
                    nombre = this.lockers[locker].nombre;
                }
            });

            if (nombre != null) {
                this.triggers.valido = false;
                this.triggers.validoMensaje = "· Locker ocupado por: " + nombre;
            } else {
                this.triggers.valido = true;
                this.triggers.validoMensaje = "✓ Locker disponible";
            }
        },
        editarLocker(){
            if (this.locker == null || this.locker == '') {
                this.triggers.valido = false;
                this.triggers.validoMensaje = "· Ingresa un locker";
                return null
            }

            // console.log(firebase);
            // console.log(".............");
            // console.log(firebase.database());
            // console.log(".............");
            // console.log(firebase.database().ref("/lockers/"+this.lockerEditable.id));
            // console.log(".............");

            firebase.database().ref("/lockers/"+this.lockerEditable.id).update({
                dni: this.lockerEditable.dni,
                fecha: this.obtenerFecha(),
                locker: this.locker,
                nombre: this.lockerEditable.nombre,
            }).then(()=>{
                this.success = true;
                this.successMensaje = "✓ Locker "+ this.locker +" asignado";
                this.seReasigno = true;
                setTimeout(() => {
                    this.close();
                }, 1000);
            }).catch((error)=>{
                console.log(error);
            })
        },
        vaciarLocker(){
            firebase.database().ref("lockers/"+this.lockerEditable.id).remove().then(()=>{
                this.success = true;
                this.successMensaje = "✓ Locker "+ this.locker +" vaciado";
                this.seReasigno = true;
                setTimeout(() => {
                    this.close();
                }, 1000);
            });
        },
        close(){
            this.$emit('cerrar-modal-editar',this.seReasigno);
        },
        obtenerFecha(){
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            var today = dd + '/' + mm + '/' + yyyy;
    
            return today;
        },
        obtenerHora(){
            var time = new Date();
            let current = ("0" + time.getHours()).slice(-2) + ":";
            current +=  ("0" + time.getMinutes()).slice(-2);
            return current
        },
        formatoCero(numero) {
            var cero = 3 - numero.toString().length + 1;
            return Array(+(cero > 0 && cero)).join("0") + numero;
        }
        
    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 40%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>