<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters">
                            <div class="col-10">Seleccionaste al empleado: {{ nombreEmpleado + ' ' + apellidoEmpleado }}</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> Cerrar </button>
                            </div>
                        </div>
                        <div class="modal-body" v-if="!success">
                            <!-- Form -->
                            <label for="selectEmpleado" class="form-label">Selecciona permisos a asignar</label>
                            <div class="alert alert-success w-100" v-if="seReasigno">✓ Asignado en la operación: {{operacionSeleccionada}}</div>
                            <!-- Add error -->
                            <!-- Form select and buttons -->
                            <div class="row align-items-center">
                                <!-- Select -->
                                <div class="col-12" style="overflow: hidden;overflow-y: scroll;height: 300px;">
                                   <table id="modulos" class="table">
                              <thead>
                                <tr>
                                  <th>Modulo/plataforma</th>
                                 
                                  <th>Activa/Desactiva</th>

                                  <th>Editor</th>
                                </tr>
                              </thead>
                              <tbody>
                                <input type="hidden" id="dni" :value="dniEmpleado">
                                <tr v-for="modulos in modulos" v-bind:key="modulos">
                                    <td>{{modulos.modulo}}</td>
                                   
                                   
                                    <td><input type="checkbox" class="lector" :id="'lector-'+modulos.modulo" :data-modulo="modulos.modulo" :data-key="modulos.id" style="transform: scale(2);"></td>

                                    <td><input type="checkbox" class="editor" :id="'editor-'+modulos.modulo" :data-modulo="modulos.modulo" data-key="modulos.id" style="transform: scale(2);"></td>
                                    
                                   
                                  
                                   
                                  
                                </tr>
                              </tbody>
                            </table>
                                </div>
                             
                            </div>
                            <p class="text-danger my-2" v-if="errors.operacion">· {{errors.operacionMessage}}</p>                        
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
                         Los permisos se actualizan automaticamente
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from "axios";
import Select2 from 'vue3-select2-component';
// import tableConfig from '../../dataTableConfig';
import firebase from "firebase/app";
import "firebase/database";
// import ListaCarga from '../ListaCarga.vue';

import $ from 'jquery';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();


export default {
    
    name: "ModalAdministrar",
    props: ['dniEmpleado','nombreEmpleado','apellidoEmpleado'],
    data(){
        return {
            errors: {
                operacion: false,
                observacion: false,
            },
            modulos:[]
           
        }
    },
    mounted(){
        this.obtenerOperaciones();   

       

        // this.observacion = (Math.random() < 0.5) ? "Cambiar el coso del cosito por x cosito" : ""

        // let observacion = firebase.database().ref('/observacion/13');
        // console.log(observacion.get());

        // axios
        //     .get("https://aplicativos.firebaseio.com/observacion/" + this.legajoEmpleado)
        //     .then(response => {
        //         this.observacion = response.data;
        //     })
        //     .catch(e => console.log(e));

        
        // console.log(this.nombreEmpleado);
        // console.log(this.legajoEmpleado);
        // console.log(this.operacion);
        // console.log(this.idDistribucion);
    },
    methods: {
        guardarSeleccion({id, text}){
            // this.operacionSeleccionada = id;
            // this.nombre = text;
            this.errors.operacion = false;
            // console.log(text + " id: " + id);
        },
       
        distribuirEmpleado(){
            

            $(".lector").each(function(){
                var mod = $(this).data("modulo");
                console.log("modulos")
                console.log(mod)
                if (esLector(mod)) {
                    $(this).prop('checked', true);
                }
            });

            firebase.database().ref('distribucion/' + this.operacionSeleccionada).push({
                legajo: this.legajoEmpleado,
                nombre: this.apellidoEmpleado + " " + this.nombreEmpleado
            }, (error) => {
                if (error) {
                    alert("error: no se pudo asignar el empleado en la operacion /n verificá la conexión a internet o reintentalo");
                } else {
                    console.log("empleado asignado asignado a la operación: " + this.operacionSeleccionada);
                    this.seReasigno = true;
                }
            });





        },




        async obtenerOperaciones(){

           var dni = $("#dni").val();





const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/modulos.json?print=pretty');
const axiosrequest2 = axios.get('https://auth.foodservice.com.ar/?type=user&dni='+dni+'&access_token=1234567');

    await axios.all([axiosrequest1,axiosrequest2]).then(axios.spread((modulos,permisos) => { 
 var modulo = [];


            $.each(modulos.data,function(clave,valor){
                modulo.push({
                        id: clave,
                        modulo: valor.ModulosAccess
                    })
            })


const esLector = (permiso) =>{
                var status = false;
                $.each(permisos.data[0].Permisos,function(clave,valor){
                       if (valor.ModulosAccess==permiso) {
                           status = valor.Lector;
                       }
                 })

                return status;
}


const esEditor = (permiso) =>{
                var status = false;
                $.each(permisos.data[0].Permisos,function(clave,valor){
                       if (valor.ModulosAccess==permiso) {
                           status = valor.Editor;
                       }
                 })

                return status;
}



       
setTimeout(function(){


            $(".lector").each(function(){
                var mod = $(this).data("modulo");
                console.log("modulos")
                console.log(mod)
                if (esLector(mod)) {
                    $(this).prop('checked', true);
                }
            
                 $(this).change(function(){
                    console.log(mod)
                    var verificar = $(this).prop('checked');
                    console.log(verificar)

                    if (verificar) {

                       firebase.database().ref('permisos/' + $("#dni").val()+'/'+$(this).data("key")).update({
                        ModulosAccess: mod,
                        Lector: true,
                        Editor: false
                        }, (error) => {
                                if (error) {
                               
                                } else {
                               
                                }
                        });
                   }else{

                        firebase.database().ref('permisos/' + $("#dni").val()+'/'+$(this).data("key")).update({
                        ModulosAccess: mod,
                        Lector: false,
                        Editor: false
                        }, (error) => {
                        if (error) {
                       
                        } else {
                       
                        }
                        });

                   }
                        


                 })
                   


            });


            $(".editor").each(function(){
                var mod = $(this).data("modulo");
                console.log("modulos")
                console.log(mod)
                if (esLector(mod)) {
                    $(this).prop('checked', true);
                }
            
                 $(this).change(function(){
                    console.log(mod)
                    var verificar = $(this).prop('checked');
                    console.log(verificar)

                    if (verificar) {

                       firebase.database().ref('permisos/' + $("#dni").val()+'/'+$(this).data("key")).update({
                        ModulosAccess: mod,
                        Lector: true,
                        Editor: true
                        }, (error) => {
                                if (error) {
                               
                                } else {
                               
                                }
                        });
                   }else{

                        firebase.database().ref('permisos/' + $("#dni").val()+'/'+$(this).data("key")).update({
                        ModulosAccess: mod,
                        
                        Editor: false
                        }, (error) => {
                        if (error) {
                       
                        } else {
                       
                        }
                        });

                   }
                        


                 })
                   


            });


},3000)



           

          

                 
                 this.modulos = modulo;


   })).then(() => { 

   


    });
           


                
        },
        obtenerDistribucionGeneral(){
            axios
            .get("https://aplicativos.firebaseio.com/distribucion.json?print=pretty")
            .then(response => {
                if (response.status != 200) console.log("Hubo un error al obtener la distribucion general de empleados");
                this.distribucion = response.data;

            })
            .catch(e => {
                console.log(e);
            });
        },
        close(){
            this.$emit('cerrar-modal-asignar',this.seReasigno);
        },
        
    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

 
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>