<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                  <th>Dni</th>
                                  <th>Legajo</th>
                                  <th>Nombre</th>
                                  <th>Empleado por</th>
                                  <th>Ingreso</th>
                                  <th>Categoria</th>
                                 
                                  
                                  <th>Accion</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="personal in personal" v-bind:key="personal">
                                    <td>{{personal.dni}}</td>
                                    <td>{{personal.legajo}}</td>
                                    <td>{{personal.apellido}} <br> {{personal.nombre}}</td>
                                     <td v-if="personal.razonsocial=='Food Service America'"><span class="badge badge-success">Food Service America</span></td>
                                    <td v-else><span class="badge badge-info">Food Service Catering</span></td>
                                    <td>{{personal.ingreso}}</td>
                                    <td>{{personal.categoria}}</td>
                                    
                                   
                                  
                                   
                                    <td>
                                        <button v-if="isEditor('Personal')==true" type="button" class="btn btn-primary btn-xs" @click.prevent="abrirModalAdministrar(personal.dni, personal.nombre, personal.apellido)">Administrar</button>
                                        <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Administrar</button>

                                       

                                        
                                   
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                <modal-administrar
                    v-if="modalAdministrar.open" 
                  
                    :dniEmpleado="modalAdministrar.dni"
                    :nombreEmpleado="modalAdministrar.nombre"
                    :apellidoEmpleado="modalAdministrar.apellido"
                   
                    @cerrar-modal-asignar="refrescarPersonal($event)"
                >
                </modal-administrar>


        <modal-observar
            v-if="modalObservar.open" 
                    :dniEmpleado="modalObservar.dni"
                    :legajoEmpleado="modalObservar.legajo"
                    :nombreEmpleado="modalObservar.nombre"
                    :apellidoEmpleado="modalObservar.apellido"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalObservar.open=false"
        ></modal-observar>

                <modal-legales
                    v-if="modalLegales.open" 
                    :dniEmpleado="modalLegales.dni"
                    :nombreEmpleado="modalLegales.nombre"
                    :apellidoEmpleado="modalLegales.apellido"
                    :legajoEmpleado="modalLegales.legajo"
                    @cerrar-modal-legales="refrescarPersonal($event)"
                ></modal-legales>

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import ModalLegales from './ModalLegales';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: 'Personal',
    components: {ListaCarga, ModalAdministrar, ModalLegales,ModalObservar},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Personal',
            descripcion: 'En este modulo usted podra visualizar y administrar todo  el personal de la empresa',
            triggers: {load:true},
            personal: [],
             modalObservar: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
            },
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {
        isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        observarPersonalId(dni, nombre, apellido, legajo){
            this.modalObservar.dni = dni;
            this.modalObservar.nombre = nombre;
            this.modalObservar.apellido = apellido;
            this.modalObservar.legajo = legajo;

            console.log(apellido)
            setTimeout(() => {
                this.modalObservar.open = true;
            }, 200);
        },
        abrirModalAdministrar(dni,nombre,apellido){
            this.modalAdministrar.dni = dni;
            this.modalAdministrar.nombre = nombre;
            this.modalAdministrar.apellido = apellido;
         
            this.modalAdministrar.open = true;
        },
        abrirModalLegales(dni,nombre,apellido,legajo){
            this.modalLegales.dni = dni;
            this.modalLegales.nombre = nombre;
            this.modalLegales.apellido = apellido;
            this.modalLegales.legajo = legajo;
            this.modalLegales.open = true;
        },
        async obtenerPersonal(){
            this.triggers.load = true;
            const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty');
            const axiosrequest3 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
            const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
            const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
            const axiosrequest6 = axios.get('https://aplicativos.firebaseio.com/legales.json?print=pretty');
            const axiosrequest7 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');

             const axiosrequest8 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');
             const axiosrequest9 = axios.get('https://aplicativos.firebaseio.com/observacion_personal.json?print=pretty');
              const axiosrequest10 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_ext.json?print=pretty');

            await axios.all([axiosrequest1, axiosrequest2, axiosrequest3, axiosrequest4, axiosrequest5, axiosrequest6, axiosrequest7,axiosrequest8,axiosrequest9,axiosrequest10]).then(
            axios.spread((operaciones, personal, distribucion, zonal, categorias, legales, sectores,personal_cat,observaciones,externos) => {
                var lista = [];
                var observacion = false;

                 console.log(personal_cat.data);


                const result_categoria = (id) => {
                    var categoria = "";
                    $.each(categorias.data, function(clave, value) {

                        if (value.codigo == id) {
                            categoria = value.nombre;
                        }
                    })
                    return categoria;
                }

                const result_distribucion = (id) => {
                    var distri = "";
                    $.each(distribucion.data, function(clave, value) {
                        $.each(value, function(k, v) {
                            if (v.legajo == id) {
                                distri = {
                                    operacion: clave,
                                    idDistribucion: k
                                }                               
                            }
                        })

                    });
                    return distri;
                }

                const result_operacion = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].operacion;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.name;
                             
                            }
                        })
                    }
                    return operacion;
                }




                const result_madre = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "-";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.empresa_madre;
                             
                            }
                        })
                    }
                    return operacion;
                }




const result_tipo = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "Planta Central";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.tipo_empresa;
                             
                            }
                        })
                    }
                    return operacion;
                }



                const result_operacion_bejerman = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].id;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.catering_id;
                             
                            }
                        })
                    }
                    return operacion;
                }


                 const result_apropiacion = (id) => {
                    var operacion = "";
                    var apropiacion = "";
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                          
                            apropiacion = sectores.data[sector].id_empresa;

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                             
                                apropiacion = value.centroapropiacion_id
                            }
                        })
                    }
                    return apropiacion;
                }

                const result_zonal = (id) => {
                    var zonales = "";
                    $.each(zonal.data, function(clave, value) {
                        if (clave == id) {
                            zonales = value.nombre;
                        }
                    })
                    return zonales;
                }

                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }

               
                $.each(personal.data, function(clave, valor) {
                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                    if(legales.data[valor.leg_numdoc] != null) return true;
                    if (valor.leg_fecegr == null) {
                        let distribucion = result_distribucion(valor.leg_numero);
                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "razonsocial": "Food Service America",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                             "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                            "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                            "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                            "dni": valor.leg_numdoc,
                            "observacion":observacion,
                        })
                    }
                });

                $.each(personal_cat.data, function(clave, valor) {
                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                    if(legales.data[valor.leg_numdoc] != null) return true;
                    if (valor.leg_fecegr == null) {
                        let distribucion = result_distribucion(valor.leg_numero);
                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "razonsocial": "Food Service Catering",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                             "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                             "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                            "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                            "dni": valor.leg_numdoc,
                            "observacion":observacion,
                        })
                    }
                });


                 $.each(externos.data, function(clave, valor) {
                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                    if(legales.data[valor.leg_numdoc] != null) return true;
                    if (valor.leg_fecegr == null) {
                        let distribucion = result_distribucion(valor.leg_numero);
                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "razonsocial": "Food Service Catering",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                             "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                             "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                            "zonal": reemplazarIndefinido(result_zonal(result_distribucion(valor.leg_numero).operacion)),
                            "dni": valor.leg_numdoc,
                            "observacion":observacion,
                        })
                    }
                });



                // console.log(lista);
                this.personal = lista;
                this.triggers.load = false;
                })).then(() => {
                    $('#empresas').DataTable({
                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Ningún dato disponible en esta tabla",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                        "search": {regex:true},
                        dom: 'Bfrtip',
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });
                });

                
        },
        refrescarPersonal(seReasigno){
            this.modalAdministrar.open = false;
            this.modalLegales.open = false;
            this.modalObservar.open = false;
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

            // this.$nextTick(()=>{
            // })
        },
    },
    mounted() {
        this.obtenerPersonal();
    },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}
.observacion-existente {
    background: #10b759;
    border-radius: 50%;
    padding: 3px 4px;
    padding-top: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(60px, -20px);
}
</style>