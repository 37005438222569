<template>
  <div class="card">
      <div class="card-body">
        <div class="row mb-4 align-items-center">
            <div class="col">
                <h4 class="">
                    <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:2px" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16"> <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
                    Edición de personal
                </h4>
            </div>
            <div class="col-4 text-right">
                <button class="btn btn-danger w-100" @click.prevent="trigger.baja=true" v-if="!trigger.baja">
                    <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:2px" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line></svg>
                    &nbsp;
                    Dar de baja
                </button>
            </div>
        </div>

        <form>
            <div class="form-row" v-if="trigger.baja">
                <div class="col">
                    <div class="border border-danger rounded p-3">
                        <h5 class="mb-3">Seleccioná una fecha de egreso</h5>
                        <div class="alert alert-danger">
                            <b>¡Cuidado!</b> Esta acción no se puede deshacer, estas dando de baja a <b>{{empleado.leg_nombre}} {{empleado.leg_apellido}}</b>, con DNI: <b>{{empleado.leg_numdoc}}</b>
                        </div>
                        <div class="mb-3">
                            <input type="date" class="form-control w-100" v-model="leg_fecegr">
                            <div class="text-danger mt-1" v-if="error['leg_fecegr']">&otimes; {{error['leg_fecegr']}}</div>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-light mr-2" @click.prevent="trigger.baja=false">
                                <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:2px" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                &nbsp;
                                Cancelar
                            </button>
                            <button class="btn btn-danger" @click.prevent="submitBaja()">
                                <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:2px" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line></svg>
                                &nbsp;
                                Dar de baja
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <form v-if="!trigger.baja">
            <div class="form-row mb-4">
                <div class="col">
                    <div class="border border-dark rounded p-3"> 
                        <div class="form-group">
                            <h5 class="mb-3">Empresa donde se asignara el personal</h5>
                            <label class="form-label">Empresa*</label>
                            <select class="form-control" v-model="db_selected">
                                <option value="null" disabled selected>Seleccionar</option>
                                <option :value="item.db" v-for="(item,d) in base_de_datos" :key="d">{{item.label}}</option>
                            </select>
                        </div>
                        <div class="text-danger mt-1" v-if="error.db_selected != undefined || error.db_selected != null">&otimes; {{error.db_selected}}</div>
                    </div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-4">
                    <label class="form-label">Nombre*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_nombre">
                    <div class="text-danger mt-1" v-if="hasError('leg_nombre')">&otimes; {{error.empleado.leg_nombre}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Apellido*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_apellido">
                    <div class="text-danger mt-1" v-if="hasError('leg_apellido')">&otimes; {{error.empleado.leg_apellido}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Número de documento*</label>
                    <input type="tel" class="form-control" v-model="empleado.leg_numdoc" @input="onlyNumbers($event,'leg_numdoc')">
                    <div class="text-danger mt-1" v-if="hasError('leg_numdoc')">&otimes; {{error.empleado.leg_numdoc}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-4">
                    <label class="form-label">Fecha de nacimiento*</label>
                    <input type="date" class="form-control" ref="leg_fecnac" v-model="empleado.leg_fecnac.date">
                    <div class="text-danger mt-1" v-if="hasError('leg_fecnac')">&otimes; {{error.empleado.leg_fecnac}}</div>
                </div>
                <div class="col mb-4">
                    <div class="form-group">
                        <label class="form-label">Sexo*</label>
                        <select class="form-control" v-model="empleado.leg_sexo">
                            <option value="null" disabled selected>Seleccionar sexo</option>
                            <option value="0">Femenino</option>
                            <option value="1">Masculino</option>
                        </select>
                    </div>
                    <div class="text-danger mt-1" v-if="hasError('leg_sexo')">&otimes; {{error.empleado.leg_sexo}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-4">
                    <label class="form-label">Domicilio*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_domic">
                    <div class="text-danger mt-1" v-if="hasError('leg_domic')">&otimes; {{error.empleado.leg_domic}}</div>
                </div>
                <div class="col-2 mb-4">
                    <label class="form-label">Altura*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_puerta">
                    <div class="text-danger mt-1" v-if="hasError('leg_puerta')">&otimes; {{error.empleado.leg_puerta}}</div>
                </div>
                <div class="col-1 mb-4">
                    <label class="form-label">Piso</label>
                    <input type="text" class="form-control" v-model="empleado.leg_piso">
                    <div class="text-danger mt-1" v-if="hasError('leg_piso')">&otimes; {{error.empleado.leg_piso}}</div>
                </div>
                <div class="col-1 mb-4">
                    <label class="form-label">Depto</label>
                    <input type="text" class="form-control" v-model="empleado.leg_depto">
                    <div class="text-danger mt-1" v-if="hasError('leg_depto')">&otimes; {{error.empleado.leg_depto}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Entre calles</label>
                    <input type="text" class="form-control" v-model="empleado.leg_entrecalles">
                    <div class="text-danger mt-1" v-if="hasError('leg_entrecalles')">&otimes; {{error.empleado.leg_entrecalles}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-4">
                    <label class="form-label">Localidad</label>
                    <input type="text" class="form-control" v-model="empleado.leg_locali">
                    <div class="text-danger mt-1" v-if="hasError('leg_locali')">&otimes; {{error.empleado.leg_locali}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Código postal*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_codpos">
                    <div class="text-danger mt-1" v-if="hasError('leg_codpos')">&otimes; {{error.empleado.leg_codpos}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-5">
                    <label class="form-label">Telefono</label>
                    <input type="text" class="form-control" v-model="empleado.leg_telefono" @input="onlyNumbers($event,'leg_telefono')">
                    <div class="text-danger mt-1" v-if="hasError('leg_telefono')">&otimes; {{error.empleado.leg_telefono}}</div>
                </div>
                <div class="col mb-5">
                    <label class="form-label">Celular</label>
                    <input type="text" class="form-control" v-model="empleado.leg_celular" @input="onlyNumbers($event,'leg_celular')">
                    <div class="text-danger mt-1" v-if="hasError('leg_celular')">&otimes; {{error.empleado.leg_celular}}</div>
                </div>
                <div class="col mb-5">
                    <label class="form-label">Correo electrónico*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_mail">
                    <div class="text-danger mt-1" v-if="hasError('leg_mail')">&otimes; {{error.empleado.leg_mail}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-5">
                    <label class="form-label">CUIL*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_cuil" @input="onlyNumbers($event,'leg_cuil')">
                    <div class="text-danger mt-1" v-if="hasError('leg_cuil')">&otimes; {{error.empleado.leg_cuil}}</div>
                </div>
                <div class="col mb-5">
                    <label class="form-label">CBU*</label>
                    <input type="text" class="form-control" v-model="empleado.cbu" @input="onlyNumbers($event,'cbu')">
                    <div class="text-danger mt-1" v-if="hasError('cbu')">&otimes; {{error.empleado.cbu}}</div>
                </div>
                <div class="col-2 mb-4">
                    <div class="form-group">
                        <label class="form-label">Discapacidad*</label>
                        <select class="form-control" v-model="empleado.leg_discap">
                            <option value="0" selected>No</option>
                            <option value="1">Sí</option>
                        </select>
                    </div>
                    <div class="text-danger mt-1" v-if="hasError('leg_discap')">&otimes; {{error.empleado.leg_discap}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-4">
                    <label class="form-label">Leg Clase</label>
                    <input type="text" class="form-control" v-model="empleado.leg_clase" @input="onlyNumbers($event,'leg_clase')">
                    <div class="text-danger mt-1" v-if="hasError('leg_clase')">&otimes; {{error.empleado.leg_clase}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Cat Código</label>
                    <input type="text" class="form-control" v-model="empleado.cat_codigo" @input="onlyNumbers($event,'cat_codigo')">
                    <div class="text-danger mt-1" v-if="hasError('cat_codigo')">&otimes; {{error.empleado.cat_codigo}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Leg Básico*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_basico">
                    <div class="text-danger mt-1" v-if="hasError('leg_basico')">&otimes; {{error.empleado.leg_basico}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <!-- <div class="col mb-4">
                    <label class="form-label">Leg Fecing</label>
                    <input type="date" class="form-control" v-model="empleado.leg_fecing.date">
                    <div class="text-danger mt-1" v-if="hasError('leg_fecing')">&otimes; {{error.empleado.leg_fecing}}</div>
                </div> -->
                <div class="col mb-4">
                    <label class="form-label">Fecha de ingreso*</label>
                    <input type="date" class="form-control" v-model="empleado.leg_fecingr.date">
                    <div class="text-danger mt-1" v-if="hasError('leg_fecingr')">&otimes; {{error.empleado.leg_fecingr}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Número de legajo*</label>
                    <input type="text" class="form-control" v-model="empleado.leg_numero"  @input="onlyNumbers($event,'leg_numero')" @change="checkLegajoNumero">
                    <div class="text-danger mt-1" v-if="hasError('leg_numero')">&otimes; {{error.empleado.leg_numero}}</div>
                    <div class="text-success mt-1" v-if="legajoExiste!=null && !legajoExiste">&check; El número es único</div>
                    <div class="text-danger mt-1" v-if="legajoExiste!=null && legajoExiste">&otimes; El número ya existe</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-4">
                    <label class="form-label">Car código</label>
                    <input type="text" class="form-control" v-model="empleado.car_codigo"  @input="onlyNumbers($event,'car_codigo')">
                    <div class="text-danger mt-1" v-if="hasError('car_codigo')">&otimes; {{error.empleado.car_codigo}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Sec códig</label>
                    <input type="text" class="form-control" v-model="empleado.sec_codigo"  @input="onlyNumbers($event,'sec_codigo')">
                    <div class="text-danger mt-1" v-if="hasError('sec_codigo')">&otimes; {{error.empleado.sec_codigo}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">Lpg códig</label>
                    <input type="text" class="form-control" v-model="empleado.lpg_codigo"  @input="onlyNumbers($event,'lpg_codigo')">
                    <div class="text-danger mt-1" v-if="hasError('lpg_codigo')">&otimes; {{error.empleado.lpg_codigo}}</div>
                </div>
            </div>
            <div class="form-row mb-3">
                <div class="col mb-4">
                    <label class="form-label">leg_apecas</label>
                    <input type="text" class="form-control" v-model="empleado.leg_apecas">
                    <div class="text-danger mt-1" v-if="hasError('leg_apecas')">&otimes; {{error.empleado.leg_apecas}}</div>
                </div>
                <!-- <div class="col mb-4">
                    <label class="form-label">leg_fecmod</label>
                    <input type="date" class="form-control" v-model="empleado.leg_fecmod.date">
                    <div class="text-danger mt-1" v-if="hasError('leg_fecmod')">&otimes; {{error.empleado.leg_fecmod}}</div>
                </div> -->
                <div class="col mb-4">
                    <label class="form-label">leg_prvdom</label>
                    <input type="text" class="form-control" v-model="empleado.leg_prvdom"  @input="onlyNumbers($event,'leg_prvdom')">
                    <div class="text-danger mt-1" v-if="hasError('leg_prvdom')">&otimes; {{error.empleado.leg_prvdom}}</div>
                </div>
                <div class="col mb-4">
                    <label class="form-label">leg_prvtra</label>
                    <input type="text" class="form-control" v-model="empleado.leg_prvtra" @input="onlyNumbers($event,'leg_prvtra')">
                    <div class="text-danger mt-1" v-if="hasError('leg_prvtra')">&otimes; {{error.empleado.leg_prvtra}}</div>
                </div>
                <!-- <div class="col mb-4">
                    <label class="form-label">leg_fecegr</label>
                    <input type="date" class="form-control" v-model="empleado.leg_fecegr.date">
                    <div class="text-danger mt-1" v-if="hasError('leg_fecegr')">&otimes; {{error.empleado.leg_fecegr}}</div>
                </div> -->
            </div>
            <div class="form-row">
                <div class="col text-right">
                    <button class="btn btn-outline btn-outline-danger mr-3" @click.prevent="$emit('end',false)">
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:2px" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                        Cancelar
                    </button>
                    <button class="btn btn-success" @click.prevent="submit($event)">
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom:2px" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        Modificar información
                    </button>
                </div>
            </div>
        </form>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
let fireRRHH = firebase.apps[2];

export default {
    name:'UpdatePersonal',
    props:{selected:Object},
    data() {
        return {
            empleado:{
                car_codigo: null,
                cat_codigo: null,
                cbu: null,
                leg_apecas: null,
                leg_apellido: null,
                leg_basico: null,
                leg_clase: null,
                leg_codpos: null,
                leg_cuil: null,
                leg_depto: null,
                leg_discap: 0,
                leg_domic: null,
                leg_entrecalles:null,
                leg_fecing: { date: null },
                leg_fecingr: { date: null },
                leg_fecmod: { date: null },
                leg_fecnac: { date: null },
                // leg_fecegr: { date: null },
                leg_mail: null,
                leg_locali: null,
                leg_nombre: null,
                leg_numdoc: null,
                leg_numero: null,
                leg_piso: null,
                leg_prvdom: null,
                leg_prvtra: null,
                leg_puerta: null,
                leg_sexo: null,
                leg_telefono: null,
                leg_celular:null,
                lpg_codigo: null,
                sec_codigo: null,
            },
            error:{},
            db_selected:null,
            base_de_datos:[
                {label:'Food Service América',db:'personal'},
                {label:'Food Service Catering',db:'personal_cat'},
                {label:'Externos',db:'personal_ext'},
                {label:'Alimentación',db:'personal_ali'},
            ],
            datesToValidate:['leg_fecingr', 'leg_fecnac'],
            excludeValidate:['leg_depto','leg_piso','leg_fecmod','leg_fecing','leg_apecas','leg_telefono','leg_entrecalles', 'leg_locali','leg_celular','car_codigo' ,'cat_codigo' ,'leg_apecas' ,'leg_clase' ,'leg_prvdom' ,'leg_prvtra' ,'lpg_codigo' ,'sec_codigo'],
            numericProps:['leg_clase','leg_discap','leg_numero','leg_sexo'],
            legajoExiste:null,
            trigger:{baja:false},
            leg_fecegr: null,
        }
    },
    mounted() {
        let noReferenceEmpleado = JSON.parse(JSON.stringify(this.selected));
        Object.keys(this.empleado).forEach((prop)=>{
            if(noReferenceEmpleado[prop] == undefined) noReferenceEmpleado[prop] = "";
            if(prop == 'leg_fecingr' || prop == 'leg_fecmod' || prop == 'leg_fecing' || prop == 'leg_fecnac'){
                this.empleado[prop]['date'] = new Date(noReferenceEmpleado[prop]['date']).toLocaleDateString('en-CA');          
                
            }else {
                this.empleado[prop] = noReferenceEmpleado[prop];
            }
        });

        this.db_selected = this.selected.config.db_name;

        
    },
    methods: {
        hasError(prop){
            return this.error['empleado'] != undefined && this.error['empleado'][prop] != undefined
        },
        submitBaja(){
            if (this.isEmpty(this.leg_fecegr)) {
                this.error['leg_fecegr'] = "Seleccione una fecha de baja, es obligatorio para continuar";
                return null;
            }

            this.empleado['leg_fecegr'] = {date:this.formatDate(this.leg_fecegr)};

            fireRRHH.database().ref(`${this.db_selected}/${this.selected.config.fireId}`).update(this.empleado).then(()=>{
                console.info('%cEmpleado dado de baja','color:#0f0');
                this.$emit('end',true);
            }).catch((e)=>{
                this.errorLog(e,'Tuvimos un error al guardar la información. Para reintentar pulse CANCELAR, si desea recargar la página pulse ACEPTAR. ATENCIÓN: Si recarga se perderan los datos actuales.');
            });
        },
        submit(event){
            let valid = true;
            // se comprueba seleccion de base de datos
            if(this.isEmpty(this.db_selected)) {
                this.error['db_selected'] = 'Asigne el nuevo personal en una empresa';
                valid = false;
            }
            // se comprueba el formulario y se muestran errores en la vista
            if(!this.isValidForm()) valid = false;

            if(!valid) {
                console.error('El fomulario es inválido');
                return null; // Se cancela la creacion
            }

            // El formulario es valido
            console.info('%cEmpleado válido, se procede a limpiar propiedades y subir','color:#0f0');

            // Se actualiza la vista para mostrar es estado de carga
            event.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Guardando
            `
            event.target.disabled = true;
            for (let input of document.querySelectorAll('form input,form select')) {
                input.disabled = true;
            }


            // Se setean los valores automáticos
            this.empleado.leg_fecmod.date = this.formatDate("Y-m-d H:i:s",new Date());
            // Se resetean los valores ingresados por fechas más compatibles a PHP (Se agrega la hora principalmente)
            this.empleado.leg_fecingr.date = this.formatDate("Y-m-d H:i:s",this.empleado.leg_fecingr.date);
            this.empleado.leg_fecnac.date = this.formatDate("Y-m-d H:i:s",this.empleado.leg_fecnac.date);
            // this.empleado.leg_fecegr.date = "";

            // Se setean los valores numéricos
            this.numericProps.forEach((prop)=>this.empleado[prop] = isNaN(parseInt(this.empleado[prop])) ? '' : parseInt(this.empleado[prop]));
            this.empleado['leg_basico'] = isNaN(this.empleado['leg_basico']) ? '' : parseFloat(this.empleado['leg_basico']);
            // Se setean en vacio ("") propiedades nulas
            Object.keys(this.empleado).forEach((prop)=>{
                if (this.isEmpty(this.empleado[prop])) {
                    this.empleado[prop] = "";
                }
            });

            // Se sube el empleado
            fireRRHH.database().ref(`${this.db_selected}/${this.selected.config.fireId}`).update(this.empleado).then(()=>{
                console.info('%cEmpleado actualizado','color:#0f0');
                this.$emit('end',true);
            }).catch((e)=>{
                this.errorLog(e,'Tuvimos un error al guardar la información. Para reintentar pulse CANCELAR, si desea recargar la página pulse ACEPTAR. ATENCIÓN: Si recarga se perderan los datos actuales.');
            }).then(()=>{
                event.target.innerHTML = "Modificar información"
                event.target.disabled = false;
                for (let input of document.querySelectorAll('form input,form select')) {
                    input.disabled = false;
                }
            })
        },
        isValidForm(){
            let valid = true;
            // Se pasa el valor de legajoExiste
            valid = (this.legajoExiste==null || this.legajoExiste == false) ? true : false;
            Object.keys(this.empleado).forEach((prop)=>{
                // Excluye de la validacion propiedades que no son obligatorias
                let isExclude = this.excludeValidate.some((el)=>el==prop);
                if(isExclude) return;
                // Limpia la propiedad error iterada, si existe
                if (this.error['empleado'] != undefined && this.error['empleado'][prop]!=undefined) {
                    delete this.error['empleado'][prop];
                }
                // Comprobar si el prop es de tipo fecha
                let isInputDate = this.datesToValidate.some((el)=>el==prop);
                // Comprueba si está vacia, null o indefinido
                if((isInputDate && this.isEmpty(this.empleado[prop].date)) || this.isEmpty(this.empleado[prop])){
                    if (this.error['empleado'] == undefined) this.error['empleado'] = {};
                    this.error['empleado'][prop] = 'El campo es obligatorio';
                    valid = false;
                }else if(prop == 'leg_mail'){
                    /*eslint-disable*/
                    if (!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.empleado[prop])) {
                        if (this.error['empleado'] == undefined) this.error['empleado'] = {};
                        this.error['empleado'][prop] = 'El correo debe tener la forma de nombre@dominio.com';
                        valid = false;
                    }
                    /*eslint-enable*/
                }
            });
            return valid;
        },
        isEmpty(value){
            return (value == null || value === undefined || value === '');
        },
        onlyNumbers: function(event, vModel) {
            this.empleado[vModel] = event.target.value.replace(/[^0-9]/g, '');
        },
        checkLegajoNumero(){
            this.legajoExiste = null;
            if (!this.isEmpty(this.empleado.leg_numero)) {
                let existe = false;
                let promises = this.base_de_datos.map((base)=>{
                    return axios.get(`https://fs-apprrhh-dev-default-rtdb.firebaseio.com/${base.db}.json?orderBy="leg_numero"&equalTo=${parseInt(this.empleado.leg_numero)}&limitToLast=1`).then((response)=>{
                        if(response.data !== null){
                             existe = true;
                        }
                    }).catch((error)=>this.errorLog(error,'Se produjo un error al validar el número de legajo. Pulse Cancelar si desear reintentarlo o Aceptar para recargar la página'))
                });

                Promise.all(promises).then(()=>{
                    this.legajoExiste = existe;
                    if (!this.legajoExiste || this.legajoExiste === null) {
                        if (this.error['empleado'] != undefined && this.error['empleado']['leg_numero']!=undefined) {
                            delete this.error['empleado']['leg_numero'];
                        }
                    }
                })
                // this.legajoExiste = this.personal.some((p) =>p['legajo'] == this.empleado.leg_numero);

            } else {
                // Si está vacio dejamos en null para dar lugar a errores
                this.legajoExiste = null;
            }        
        },
        formatDate(format, date) {
            if (!date || date === "") {
                date = new Date();
            } else if (typeof date !== 'object') {
                // attempt to convert string/number to date object
                try {
                    if (typeof date === 'number') date = new Date(date);
                    else date = new Date(date.replace(/-/g, "/"));
                } catch (e) {
                    date = new Date();
                }
            }

            let string = '',
                mo = date.getMonth(), // month (0-11)
                m1 = mo + 1, // month (1-12)
                dow = date.getDay(), // day of week (0-6)
                d = date.getDate(), // day of the month (1-31)
                y = date.getFullYear(), // 1999 or 2003
                h = date.getHours(), // hour (0-23)
                mi = date.getMinutes(), // minute (0-59)
                s = date.getSeconds(); // seconds (0-59)

            for (let i of format.match(/(\\)*./g)) {
                switch (i) {
                    case 'j': // Day of the month without leading zeros  (1 to 31)
                        string += d;
                        break;

                    case 'd': // Day of the month, 2 digits with leading zeros (01 to 31)
                        string += (d < 10) ? "0" + d : d;
                        break;

                    case 'l': // (lowercase 'L') A full textual representation of the day of the week
                        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                        string += days[dow];
                        break;

                    case 'w': // Numeric representation of the day of the week (0=Sunday,1=Monday,...6=Saturday)
                        string += dow;
                        break;

                    case 'D': // A textual representation of a day, three letters
                        var days = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];
                        string += days[dow];
                        break;

                    case 'm': // Numeric representation of a month, with leading zeros (01 to 12)
                        string += (m1 < 10) ? "0" + m1 : m1;
                        break;

                    case 'n': // Numeric representation of a month, without leading zeros (1 to 12)
                        string += m1;
                        break;

                    case 'F': // A full textual representation of a month, such as January or March 
                        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                        string += months[mo];
                        break;

                    case 'M': // A short textual representation of a month, three letters (Jan - Dec)
                        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                        string += months[mo];
                        break;

                    case 'Y': // A full numeric representation of a year, 4 digits (1999 OR 2003)	
                        string += y;
                        break;

                    case 'y': // A two digit representation of a year (99 OR 03)
                        string += y.toString().slice(-2);
                        break;

                    case 'H': // 24-hour format of an hour with leading zeros (00 to 23)
                        string += (h < 10) ? "0" + h : h;
                        break;

                    case 'g': // 12-hour format of an hour without leading zeros (1 to 12)
                        var hour = (h === 0) ? 12 : h;
                        string += (hour > 12) ? hour - 12 : hour;
                        break;

                    case 'h': // 12-hour format of an hour with leading zeros (01 to 12)
                        var hour = (h === 0) ? 12 : h;
                        hour = (hour > 12) ? hour - 12 : hour;
                        string += (hour < 10) ? "0" + hour : hour;
                        break;

                    case 'a': // Lowercase Ante meridiem and Post meridiem (am or pm)
                        string += (h < 12) ? "am" : "pm";
                        break;

                    case 'i': // Minutes with leading zeros (00 to 59)
                        string += (mi < 10) ? "0" + mi : mi;
                        break;

                    case 's': // Seconds, with leading zeros (00 to 59)
                        string += (s < 10) ? "0" + s : s;
                        break;

                    case 'c': // ISO 8601 date (eg: 2012-11-20T18:05:54.944Z)
                        string += date.toISOString();
                        break;

                    default:
                        if (i.startsWith("\\")) i = i.substr(1);
                        string += i;
                }
            }
            return string;
        },
        errorLog(e,msg){
            console.error(e);
            if (window.confirm(msg)) {
                location.reload();
            }
        },
    },
}
</script>

<style scoped>
.form-control {
	border: 1px solid #000000ba;
	border-radius: 3px;
    color: #000000e8;
}
.form-control:focus {
	color: #04001d;
} 

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>