<template>
    <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-title w-25"><div class="load-box"></div></div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row mb-2">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-2">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                        <div class="col-4">
                            <div class="load-box"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ListaCarga", 
    props: ["filas"],  
}
</script>

<style scoped>
.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
  transition: all .2s linear;
}
</style>