<template>
	<div>
		<div class="mb-4 d-flex align-items-center justify-content-between">
			<h4 class="">Ticket en detalle</h4>
			<button class="btn btn-light" @click.prevent="$emit('end',false)">Cerrar detalle</button>
		</div>
		<div class="row mb-4">
			<div class="col">
				<b class="d-block">Apellido y nombre: </b>
				<div class="badge badge-light ">{{ticket.emisor_nombre}}</div>
			</div>
			<!-- <div class="col">
				<b class="d-block">Documento: </b>
				<div class="badge badge-light ">{{ticket.emisor_dni}}</div>
			</div> -->
			<div class="col">
				<b class="d-block">Operación: </b>
				<div class="badge badge-light ">{{ticket.operacion_nombre}}</div>
			</div>
			<div class="col">
				<b class="d-block">A cargo de Food Service: </b>
				<div class="badge badge-light ">{{(ticket.a_cargo)?'Si':'No'}}</div>
			</div>
			<div class="col">
				<b class="d-block">Fecha de emisión: </b>
				<div class="badge badge-light ">{{dateFormat(ticket.timestamp)}}</div>
			</div>
		</div>
		<div class="row mb-4">
			<div class="col-12 col-md-6">
				<b class="d-block">Motivo</b>
				<div class="badge-text">{{ticket.motivo}}</div>
			</div>
			<div class="col-12 col-md-3">
				<b class="d-block">Tipo de motivo: </b>
				<div class="badge badge-light ">{{ticket.tipo_motivo}}</div>
			</div>
			<div class="col-12 col-md-3">
				<b class="d-block">Equipamiento: </b>
				<div class="badge badge-light ">{{ticket.equipamiento}}</div>
			</div>
		</div>
		<div class="row mb-">
			<div class="col">
				<b class="d-block mb-1">Imágens adjuntas</b>
				<div v-if="ticket.imagenes != undefined && ticket.imagenes.length">
					<img class="thumb-img" v-for="(img,i) in ticket.imagenes" :key="i" :src="img" @click="showModal(img)">
				</div>
				<div class="alert alert-info" v-else>
					No se adjuntaron imágenes
				</div>
			</div>
		</div>

		<div class="modal-image" v-if="trigger.showImage" @click="showModal(null)">
			<img :src="selectedImage">
		</div>

		<div class="border border-dark rounded p-3">
			<h4 class="mb-3">Actualizar estado del ticket</h4>
			<div class="row">
				<div class="col">
					<div class="form-group">
						<label>Estado</label>
						<select class="form-control" v-model="estado">
							<option value="En espera">En espera</option>
							<option value="En proceso">En proceso</option>
							<option value="Resuelto">Resuelto</option>
						</select>
					</div>
				</div>
				<div class="col">
					<div class="form-group">
						<label>Observación</label>
						<textarea class="form-control" v-model="observacion" rows="3"></textarea>
					</div>
				</div>
			</div>
			<div class="text-right">
				<button class="btn btn-success" @click.prevent="submit($event)">
					Actualizar estado
				</button>
			</div>
		</div>

	</div>
</template>
<script>
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
const fireMantenimiento = firebase.apps[1];
export default {
    props: {
		ticket:Object,
    },
	name: 'DetalleTicket',
    data() {
        return {
			selectedImage:null,
			trigger:{showImage:false},

			observacion:'',
			estado:null,
        }
    },
	mounted() {
		if(this.ticket.observacion!=undefined) this.observacion = this.ticket.observacion;
		this.estado = this.ticket.estado;
	},

    methods: {
		submit(event){
			// Se actualiza la vista para mostrar es estado de carga
            event.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Actualizando
            `
			event.target.disabled = true;
            for (let input of document.querySelectorAll('form input,form select')) {
                input.disabled = true;
            }

			if(this.estado === this.ticket.estado && this.observacion === '') this.$emit('end',false);

			fireMantenimiento.database().ref(`tickets/${this.ticket.fireId}`).update({
				estado:this.estado,
				resuelto:(this.estado=='Resuelto')?Date.now():null,
				observacion:(this.observacion!='')?this.observacion:null,
				updated:Date.now()
			}).then(()=>{
				this.$emit('end',true);
			});

		},
		showModal(img){
			this.selectedImage = img;
			this.trigger.showImage = !this.trigger.showImage;
		},
        dateFormat(initDate,includeYear){
            let date = new Date(initDate)

            let day = date.getDate()
            let month = date.getMonth() + 1
            let year;
            if (includeYear==undefined) year = date.getFullYear()

            if(month < 10){
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-0${month}`) : (`0${day}-0${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-0${month}`) : (`${day}-0${month}-${year}`)
            }else{
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-${month}`) : (`0${day}-${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-${month}`) : (`${day}-${month}-${year}`)
            }
        }
    },
    
}

</script>

<style scoped>
.form-control {
	border: 1px solid black;
	border-radius: 3px;
}
.form-control:focus-within {
	color: black;
}
.form-control:focus-visible {
	color: black;
}

.thumb-img {
	width: 144px;
	height: 180px;
	border-radius: 6px;
	cursor: pointer;
	margin-right: 1rem;
	margin-bottom: 1rem;
	object-fit: cover;
}

.modal-image {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #00000060;
  display: flex;
  align-items: center;
  cursor:zoom-out;
}

.modal-image img {
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius: 6px;
}

@media (min-width: 768px) {
  .modal-image img {
    width: 68%;
  }
}

.badge-light{
	font-size:14px;
}
.observacion-existente {
	background: #10b759;
	border-radius: 50%;
	padding: 3px 4px;
	padding-top: 4px;
	width: 16px;
	height: 16px;
	position: absolute;
	transform: translate(60px, -20px);
}

.badge-text {
    font-size: 14px;
    background: #ececec;
    border-radius: 0.4rem;
    padding: 0.5rem;
}
</style>