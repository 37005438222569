<template>
    <div class="page-content">
      <nav class="page-breadcrumb" id="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{breadcrumbA}}</li>
          <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
        </ol>
      </nav>
      
      <div class="row">
        <div class="col">
          <div class="jumbotron">
            <h1 class="display-4">{{saludo}} {{capitalize(nombre)}}!</h1>
            <p class="lead">Sistema integral de aplicativos de Food Service America</p>
            
          </div>  
        </div>  
      </div> 
<!--
      <div class="row">
        

          <div class="col-sm-3">
            <div class="alert alert-success" role="alert">Hoy</div>
            <div id="dia1"></div>
          </div>

          <div class="col-sm-3">
            <div class="alert alert-secondary" role="alert">21/06/2021</div>
            <div id="dia2"></div>
          </div>

           <div class="col-sm-3">
            <div class="alert alert-warning" role="alert">20/06/2021</div>
            <div id="dia3"></div>
          </div>

           <div class="col-sm-3">
            <div class="alert alert-danger" role="alert">19/06/2021</div>
            <div id="dia4"></div>
          </div>

        
      </div>
-->
    </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
const userData = JSON.parse(sessionStorage.getItem('userData'));
export default {
  name: 'Dashboard',
  data: () => ({
      breadcrumbA: 'Inicio',
      breadcrumbB: 'Dashboard',
      descripcion:'Portal Dashboard',
      saludo: '',
      nombre: userData.leg_nombre,
   }),
  async mounted() {
     var fecha = new Date();
     var hora = fecha.getHours();
          if(hora >= 0 && hora < 12){
              this.saludo = "Buenos Días";
            }
            if(hora >= 12 && hora < 18){
              this.saludo = "Buenas Tardes";
            }
            if(hora >= 18 && hora < 24){
              this.saludo = "Buenas Noches";
            }

            const axiosrequest1 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty');
            const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
            const axiosrequest3 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');

            const axiosrequest4 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');
            const axiosrequest6 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');

        await axios.all([axiosrequest1, axiosrequest2, axiosrequest3,axiosrequest4,axiosrequest5,axiosrequest6]).then(axios.spread((personal, distribucion,zonal,operaciones,sectores,personal_cat) => { 



let personal_mezcla = Object.assign(personal.data, personal_cat.data);


            sessionStorage.setItem("personal",JSON.stringify(personal_mezcla))
            sessionStorage.setItem("distribucion",JSON.stringify(distribucion.data))
            sessionStorage.setItem("zonales",JSON.stringify(zonal.data))
            sessionStorage.setItem("operaciones",JSON.stringify(operaciones.data))
            sessionStorage.setItem("sectores",JSON.stringify(sectores.data))


        })).then(() => {
      
        });

    },
    methods: {
      capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      }
    }

};

</script>