<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters">
                            <div class="col-10">Seleccionaste al empleado: {{ nombreEmpleado + ' ' + apellidoEmpleado }}</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <div class="modal-body" v-if="!success">
                            <!-- Form -->
                            <label for="selectEmpleado" class="form-label">Selecciona una nueva operación</label>
                            <div class="alert alert-success w-100" v-if="seReasigno">✓ Asignado en la operación: {{operacionSeleccionada}}</div>
                            <!-- Add error -->
                            <!-- Form select and buttons -->
                            <div class="row align-items-center">
                                <!-- Select -->
                                <div class="col-10">
                                   <!-- <Select2 class="w-100" v-model="legajo" :options="empleados" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /> -->
                                   <Select2 class="w-100 input-error" id="reasignar" v-model="operacionSeleccionada" :options="operaciones" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @select="guardarSeleccion($event)" />
                                </div>
                                <!-- Button Add -->
                                <div class="col-2">
                                    <button
                                        type="button"
                                        class="btn btn-success w-100"
                                        @click="asignarOperacion()"
                                    >
                                        Distribuir
                                    </button>
                                </div>
                            </div>
                            <p class="text-danger my-2" v-if="errors.operacion">· {{errors.operacionMessage}}</p>                        
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
                            <button
                                type="button"
                                @click.prevent="close()"
                                class="btn btn-success"
                            >
                                Actualizar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from "axios";
import Select2 from 'vue3-select2-component';
// import tableConfig from '../../dataTableConfig';
import firebase from "firebase/app";
import "firebase/database";
// import ListaCarga from '../ListaCarga.vue';

import $ from 'jquery';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();


export default {
    components: {Select2},
    name: "ModalAdministrar",
    props: ['legajoEmpleado','nombreEmpleado','apellidoEmpleado','operacion', 'idDistribucion'],
    data(){
        return {
            errors: {
                operacion: false,
                observacion: false,
            },
            operaciones:[],
            operacionSeleccionada: this.operacion,
            seReasigno: false,
            observacion: '',
            observacionRealizada: false,
            observacionMensaje: null,
        }
    },
    mounted(){
        this.obtenerOperaciones();   

        $('#reasignar').select2({});

        // this.observacion = (Math.random() < 0.5) ? "Cambiar el coso del cosito por x cosito" : ""

        // let observacion = firebase.database().ref('/observacion/13');
        // console.log(observacion.get());

        // axios
        //     .get("https://aplicativos.firebaseio.com/observacion/" + this.legajoEmpleado)
        //     .then(response => {
        //         this.observacion = response.data;
        //     })
        //     .catch(e => console.log(e));

        
        // console.log(this.nombreEmpleado);
        // console.log(this.legajoEmpleado);
        // console.log(this.operacion);
        // console.log(this.idDistribucion);
    },
    methods: {
        guardarSeleccion({id, text}){
            // this.operacionSeleccionada = id;
            // this.nombre = text;
            this.errors.operacion = false;
            // console.log(text + " id: " + id);
        },
        asignarOperacion(){
            if(this.operacionSeleccionada == null){
                this.errors.operacion = true;
                this.errors.operacionMessage = "Antes de distribuir selecciona una operacion";
                return null;
            }else if(this.operacionSeleccionada == this.operacion){
                this.errors.operacion = true;
                this.errors.operacionMessage = "El empleado ya se encuentra en la operación seleccionada";
                return null;
            }

            if (this.operacion != undefined) {
                firebase.database().ref('volantes/' + this.operacion+'/' + this.idDistribucion).remove(()=>{
                    this.distribuirEmpleado();
                });
            } else {
                this.distribuirEmpleado();
            }
        },
        distribuirEmpleado(){
            /**
             *  Para evitar repetir el código en asignarOperacion
             *  Si el empleado no tiene operacion simplemente se distribuye.
             *  Si el empleado estaba en una operacion, se elimina la anterior distribucion
             */
            firebase.database().ref('volantes/' + this.operacionSeleccionada).push({
                legajo: this.legajoEmpleado,
                nombre: this.apellidoEmpleado + " " + this.nombreEmpleado,
                dni_responsable:this.operacionSeleccionada
            }, (error) => {
                if (error) {
                    alert("error: no se pudo asignar el empleado en la operacion /n verificá la conexión a internet o reintentalo");
                } else {
                    console.log("empleado asignado asignado a la operación: " + this.operacionSeleccionada);
                    this.seReasigno = true;
                }
            });
        },
        async obtenerOperaciones(){

           

const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');

    await axios.all([axiosrequest1]).then(axios.spread((operaciones) => { 
 var operacion = [];


            $.each(operaciones.data,function(clave,valor){
                operacion.push({
                        id: valor.leg_numdoc,
                        text: valor.leg_apellido+' '+valor.leg_nombre
                    })
            })

          
                  console.log(operacion)
                 this.operaciones = operacion;


   })).then(() => { });
           


                
        },
        obtenerDistribucionGeneral(){
            axios
            .get("https://aplicativos.firebaseio.com/volantes.json?print=pretty")
            .then(response => {
                if (response.status != 200) console.log("Hubo un error al obtener la distribucion general de empleados");
                this.distribucion = response.data;

            })
            .catch(e => {
                console.log(e);
            });
        },
        close(){
            this.$emit('cerrar-modal-asignar',this.seReasigno);
        },
        
    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

 
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>