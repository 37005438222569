<template>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
</template>