<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container ">
                        <div class="modal-header align-items-center no-gutters">
                            <div class="col-10" id="hola" :data-legajo="legajoEmpleado">Agregar Vacunas Covid para {{ nombreEmpleado }}</div>
                            <div class="col-2 text-right">
                                <button type="button" id="cerrar" class="btn btn-sm btn-light font-weight-bold" data-dismiss="modal" @click.prevent="close()"> x </button>
                            </div>



                        </div>
                        <!-- Success title -->
                        <div class="alert alert-success" role="alert" id="exito">
                               Datos cargados correctamente!, aguarde un instante
                            </div>


                            <div class="alert alert-success" role="alert" id="imagen">
                               <img src="" id="preview" width="1px">Imagen adjuntada correctamente!
                            </div>
                         
                            <center><a :href="certificadoEmpleado" target="_blank" class="btn btn-warning ml-2">Ver certificado cargado</a></center>
                        <div class="modal-body " v-if="!success"  id="contenido" style="overflow-y: scroll;height: 300px;">
                           
                            <table class="table" width="100%">
                                <tr>
                                    <td>Dosis</td><td>Tipo</td><td>Fecha</td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td><select id="vacunas_1">
                                        <option>Selecciona Vacuna</option>
                                        <option>Sputnik V</option>
                                        <option>Covishield</option>
                                        <option>Sinopharm</option>
                                        <option>AstraZeneca</option>
                                        <option>Moderna</option>
                                        <option>Convidecia</option>
                                        <option>Comirnaty</option>
                                    </select></td>
                                    <td><input type="date" class="fecha" id="fecha_1"></td>
                                </tr>

                                  <tr>
                                    <td>2</td>
                                    <td><select id="vacunas_2">
                                        <option>Selecciona Vacuna</option>
                                        <option>Sputnik V</option>
                                        <option>Covishield</option>
                                        <option>Sinopharm</option>
                                        <option>AstraZeneca</option>
                                        <option>Moderna</option>
                                        <option>Convidecia</option>
                                        <option>Comirnaty</option>
                                    </select></td>
                                    <td><input type="date" class="fecha" id="fecha_2"></td>
                                </tr>

                                  <tr>
                                    <td>3</td>
                                    <td><select id="vacunas_3">
                                        <option>Selecciona Vacuna</option>
                                        <option>Sputnik V</option>
                                        <option>Covishield</option>
                                        <option>Sinopharm</option>
                                        <option>AstraZeneca</option>
                                        <option>Moderna</option>
                                        <option>Convidecia</option>
                                        <option>Comirnaty</option>
                                    </select></td>
                                    <td><input type="date" class="fecha" id="fecha_3"></td>
                                </tr>

                                  <tr>
                                    <td>4</td>
                                    <td><select id="vacunas_4">
                                        <option>Selecciona Vacuna</option>
                                        <option>Sputnik V</option>
                                        <option>Covishield</option>
                                        <option>Sinopharm</option>
                                        <option>AstraZeneca</option>
                                        <option>Moderna</option>
                                        <option>Convidecia</option>
                                        <option>Comirnaty</option>
                                    </select></td>
                                    <td><input type="date" class="fecha" id="fecha_4"></td>
                                </tr>
                            
                            </table>


                           
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
   <label class="form-label btn btn-warning ml-2" for="certificado" style="margin-top: -5px;">Cargar certificado</label>
                                        

                                        <input class="form-control" type="file" id="certificado">

 <button type="button" class="btn btn-success ml-2" @click.prevent="agregarVacunas()" style="margin-left: 10px; margin-top: -5px;">Actualizar Vacunas</button>
                            

                  
                              
                               
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from 'axios';
import firebase from "firebase/app";
import $ from 'jquery';
import "firebase/database";
  import "firebase/storage";
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
let storageRef = firebase.storage().ref();


export default {
    name: "ModalObservar",
    props: ['dniEmpleado','nombreEmpleado','apellidoEmpleado','legajoEmpleado','certificadoEmpleado'],
    data(){
        return {
            errors: {
                observacion: false 
            },
            triggers: {
                success: false,
                successMessage: null,
                dangerMessage: null,
            },
            observacion: '',
            seObservo: false,
        }
    },
    mounted(){
            this.obtenerObservacion();





const resizeBase64Img=(datas,id)=>{

   new Promise( async function(resolve,reject){
        var newWidth = 300;
       
        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function()
        {        
            // We create a canvas and get its context.
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

          


    var imgwidth = img.width;
    var imgheight = img.height;
    canvas.width = newWidth;
    canvas.height = (imgheight / imgwidth) * newWidth;
    ctx.drawImage(
      this, 0, 0, imgwidth, imgheight, 0, 0, newWidth, (imgheight / imgwidth) * newWidth
    );

            // We resize the image with the canvas method drawImage();
            //ctx.drawImage(this, 0, 0, width, height);


            var dataURI = canvas.toDataURL();

            // This is the return of the Promise
            resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;

    }).then(function(result) {
        
        console.log(result)
        $("#imagen").slideDown();
          $('#preview').attr('src', result);

     })
}



$("#certificado").change(function(){


    var file = $(this).prop('files')[0];

    if (file) {
        var reader = new FileReader();

        reader.onload = function (e) {
                var imagen = e.target.result;
                resizeBase64Img(imagen,'certificado');
               

        };

        reader.readAsDataURL(file);
    }


})


        







           // console.log(this.nombreEmpleado + " " + this.legajoEmpleado);
        },
    methods: {
         isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        obtenerObservacion(){
            this.seObservo = true;
           

            this.limpiarTriggers();
            this.observacion = null;
           console.log(this.certificadoEmpleado)
            axios
            .get('https://aplicativos.firebaseio.com/vacunas_personal/'+ this.dniEmpleado +'.json?print=pretty')
            .then(response => {
                if (response.status == 200) {
                    
                    if (response.data.dosis_1!=null) {
                        console.log(response.data.dosis_1)
                        $("#vacunas_1").val(response.data.dosis_1).change();
                        $("#fecha_1").val(response.data.fecha_1);
                        
                    }

                     if (response.data.dosis_2!=null) {
                        $("#vacunas_2").val(response.data.dosis_2).change();
                        $("#fecha_2").val(response.data.fecha_2);
                       
                    }

                     if (response.data.dosis_3!=null) {
                        $("#vacunas_3").val(response.data.dosis_3).change();
                        $("#fecha_3").val(response.data.fecha_3);
                      
                    }

                     if (response.data.dosis_4!=null) {
                        $("#vacunas_4").val(response.data.dosis_4).change();
                        $("#fecha_4").val(response.data.fecha_4);
                        
                    }
                   
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        agregarVacunas(){
            this.limpiarTriggers();
            var cargado = false;




 var certificado = $("#preview").attr("src");

                           


if (certificado!="") {


            if ($("#vacunas_1 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_1").val() != '') {
              
              

console.log("dosis 1")
         
                firebase.database().ref('vacunas_personal/' + this.dniEmpleado).update({
                    dosis_1:$("#vacunas_1 option:selected").val(),
                    fecha_1:$("#fecha_1").val(),
                }, (error) => {
                    if (error) {
                        alert("Datos no cargados! verifique su conexión a internet")
                    } else {
                      
                    }
                });
                

 cargado = true;

            }


            if ($("#vacunas_2 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_2").val() != '') {
              
              

console.log("dosis 2")
         
                firebase.database().ref('vacunas_personal/' + this.dniEmpleado).update({
                    dosis_2:$("#vacunas_2 option:selected").val(),
                    fecha_2:$("#fecha_2").val(),
                }, (error) => {
                    if (error) {
                        alert("Datos no cargados! verifique su conexión a internet")
                    } else {
                      
                    }
                });
                
 cargado = true;


            }


            if ($("#vacunas_3 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_3").val() != '') {
              
              

console.log("dosis 3")
         
                firebase.database().ref('vacunas_personal/' + this.dniEmpleado).update({
                    dosis_3:$("#vacunas_3 option:selected").val(),
                    fecha_3:$("#fecha_3").val(),
                }, (error) => {
                    if (error) {
                        alert("Datos no cargados! verifique su conexión a internet")
                    } else {
                     
                    }
                });
                
 cargado = true;


            }

            if ($("#vacunas_4 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_4").val() != '') {
              
              
console.log("dosis 4")

         
                firebase.database().ref('vacunas_personal/' + this.dniEmpleado).update({
                    dosis_4:$("#vacunas_4 option:selected").val(),
                    fecha_4:$("#fecha_4").val(),
                }, (error) => {
                    if (error) {
                        alert("Datos no cargados! verifique su conexión a internet")
                    } else {
                       
                    }
                });
                
cargado = true;


            }




 if ($("#vacunas_1 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_1").val() != '') {

 }else   if ($("#vacunas_2 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_2").val() != '') {

}else   if ($("#vacunas_3 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_3").val() != '') {
}else if ($("#vacunas_4 option:selected").val() != 'Selecciona Vacuna'&&$("#fecha_4").val() != '') {
            }else{
                alert("No hay dosis cargadas!");
            }


}else{

    alert("Tiene que cargar un certificado!")
}


setTimeout(function(){
console.log(cargado)
if (cargado) {
                        console.log("insertado ok");
                       
                      $("#exito").slideDown();
                       $("#imagen").slideUp();

                      setTimeout(function(){
                         $("#cerrar").click();
                      },3000)



                                var mountainsRef = storageRef.child('certificado_vacunacion/'+new Date().getTime()+'-'+Math.ceil(Math.random()*1000));
                                      mountainsRef.putString(certificado, 'data_url').then(function(snapshot) {
                                        
                                        snapshot.ref.getDownloadURL().then(function(downloadURL) {
                                           firebase.database().ref('certificado_vacunacion/'+$("#hola").data('legajo')).update({

                                                    
                                                      certificado:downloadURL
                                                     

                                                    }, function (error) {

                                                      if (error) {
                                                        alert("Ocurrio un error! verifique su conexión a internet!")
                                                      } else {


                                                                console.log("imagen cargada");
                                                                
                                                        
                                                      }

                                                    });
                                           
                                        })
                                });


                        
            }

},1000)
            

            
        },
        quitarObservacion(){
           

            this.limpiarTriggers();
            if(this.observacion != '' && this.observacion != null){
                firebase.database().ref('vacunas_personal/' + this.dniEmpleado).remove()
                .then(function() {
                    console.log("Remove succeeded.")
                })
                .catch(function(error) {
                    console.log("Remove failed: " + error.message)
                });

                this.triggers.successMessage = "Observación eliminada";
                this.observacion = '';
                this.triggers.success = true;
                this.obtenerObservacion();
                this.seObservo = true;
                return true;
            }
            this.triggers.dangerMessage = "No hay nada para eliminar";
            
        },
        limpiarTriggers(){
            this.success = false;
            this.successMessage = null;
            this.dangerMessage = null;
        },
        close(){
            console.log("cerrar")
            this.seObservo = true;
            this.$emit('cerrar-modal-observar', this.seObservo);

        },

    }
}
</script>

<style scoped>

#preview{
    width:1px;
}

#exito{
    display:none;
}

#imagen{
    display:none;
}

h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 60%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
    padding: 0 .75rem;
    border: 1px solid #e8ebf1;
    border-radius: 0px;
    outline: none;
    color: #c9c8c8;
    
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.fecha{
    height: 38px;
    margin-top: -40px;
}
</style>