<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!success">
                            <div class="col-10">Asignar un nuevo locker</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <div class="modal-body m-0" v-if="success">
                            <h5 class="text-success text-center mt-1">✓ Locker asignado</h5>
                        </div>
                        <div class="modal-body" v-if="!success">
                            <form>
                                <div class="row">
                                    <div class="col-12">
                                        <label for="selectEmpleado" class="form-label">Selecciona una empleado</label>
                                        <Select2 if="selectEmpleado" class="w-100 input-error" :options="empleadosSelect" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @select="guardarSeleccion($event)"/>
                                        <p 
                                            class="my-2" 
                                            :class="{'text-danger': !triggers.validoNombre}" 
                                            v-if="triggers.validoNombre != null">
                                            {{triggers.validoNombreMensaje}}
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <label for="selectLocker" class="form-label mt-3">Selecciona un locker vacio</label>
                                        <input type="number" id="selectLocker" v-model="locker" @change="comprobarLockerPorNumero()" class="form-control" placeholder="100"/>
                                        <p 
                                            class="my-2" 
                                            :class="{'text-danger': !triggers.valido,  'text-success': triggers.valido}" 
                                            v-if="triggers.valido != null">
                                            {{triggers.validoMensaje}}
                                        </p>
                                    </div>
                                </div>
                            </form>










                            
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!success">
                            <button
                                type="button"
                                @click.prevent="guardarLocker()"
                                class="btn btn-success"
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import Select2 from 'vue3-select2-component';
export default {
    name: "ModalLegales",
    components: {Select2},
    props: ['empleados', 'lockers'],
    data(){
        return {
            dni: null,
            fecha: null,
            locker: null,
            nombre: null,
            empleadosSelect: [],
            triggers: {
                valido: null,
                validoMensaje: null,
                validoNombre: null,
                validoNombreMensaje: null,
            },
            seAsigno: false,
            success: false
        }
    },
    mounted(){
        /**
         *  Select2 requiere un array [{id:1,text:nombre}]
         * 	Se mapea el response a traves de las claves del objeto JSON
         */			
        this.empleadosSelect = Object.keys(this.empleados).flatMap((key)=>{
            return {
                id: this.empleados[key].leg_numero,
                text: this.empleados[key].leg_apellido + " " + this.empleados[key].leg_nombre,
                dni: this.empleados[key].leg_numdoc
            }
        });
    },
    methods: {
        guardarSeleccion({text, dni}){
            this.dni = dni;
            this.nombre = text;
            this.comprobarLockerPorNombre(text);
        },
        comprobarLockerPorNumero(){
            if (this.locker == '') {
                this.triggers.valido = null;
                return null
            }

            this.locker = this.formatoCero(this.locker);

            let nombre = null;

            Object.keys(this.lockers).map(locker => {
                if(this.lockers[locker].locker == this.locker){
                    nombre = this.lockers[locker].nombre;
                }
            });

            if (nombre != null) {
                this.triggers.valido = false;
                this.triggers.validoMensaje = "· Locker ocupado por: " + nombre;
            } else {
                this.triggers.valido = true;
                this.triggers.validoMensaje = "✓ Locker disponible";
            }
        },
        comprobarLockerPorNombre(nombre){
            let numero = null;

            Object.keys(this.lockers).map(locker => {
                if(this.lockers[locker].nombre == nombre){
                    numero = this.lockers[locker].locker;
                }
            });

            if (numero != null) {
                this.triggers.validoNombre = false;
                this.triggers.validoNombreMensaje = "· " + nombre + " ocupa el locker: " + numero;
            }else {
                this.triggers.validoNombre = null;
                this.triggers.validoNombreMensaje = null
            }
        },
        guardarLocker(){
            if(this.nombre == null || this.nombre == ''){
                this.triggers.validoNombre = false;
                this.triggers.validoNombreMensaje = "· Ingresa un empleado para que ocupe el locker";
                return null
            }

            if (this.locker == null || this.locker == '') {
                this.triggers.valido = false;
                this.triggers.validoMensaje = "· Ingresa un locker";
                return null
            }

            firebase.database().ref("/lockers").push({
                dni: this.dni,
                fecha: this.obtenerFecha(),
                locker: this.locker,
                nombre: this.nombre,
            }).then(()=>{
                this.success = true;
                this.seAsigno = true;
                setTimeout(() => {
                    this.close();
                }, 1000);
            });
        },

        close(){
            this.$emit('cerrar-modal-asignar',this.seAsigno);
        },
        obtenerFecha(){
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            var today = dd + '/' + mm + '/' + yyyy;
    
            return today;
        },
        obtenerHora(){
            var time = new Date();
            let current = ("0" + time.getHours()).slice(-2) + ":";
            current +=  ("0" + time.getMinutes()).slice(-2);
            return current
        },
        formatoCero(numero) {
            var cero = 3 - numero.toString().length + 1;
            return Array(+(cero > 0 && cero)).join("0") + numero;
        }
        
    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 40%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>