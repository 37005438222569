<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
      <li class="breadcrumb-item">{{breadcrumbA}}</li>
      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title"><i class="link-icon" data-feather="box"></i>{{breadcrumbB}}</h6>
            <div class="row">
                <div class="col">
                    <p class="card-description mb-0"> {{ descripcion }} </p>
                </div>
                <div class="col text-right">
                    <button class="btn btn-primary" @click.prevent="triggers.create = true" v-if="!triggers.create && !triggers.update">
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 2px;" width="16" height="16" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16"> <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/> <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/> </svg>
                        &nbsp;
                        Alta de personal
                    </button>
                    <button class="btn btn-light" @click.prevent="refrescarPersonal(false)" v-if="triggers.create || triggers.update">
                        <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 2px;" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/> </svg>
                        &nbsp;
                        Regresar a la lista
                    </button>
                </div>
            </div>
          </div>  
        </div>
      </div>
    </div>    

    <div class="row" v-if="triggers.create">
        <div class="col">
            <alta-personal @end="refrescarPersonal($event)"></alta-personal>
        </div>
    </div>
    <div class="row" v-if="triggers.update">
        <div class="col">
            <update-personal @end="refrescarPersonal($event)" :selected="selected"></update-personal>
        </div>
    </div>

    <lista-carga v-if="triggers.load"></lista-carga>
    <div class="row" v-if="!triggers.load && !triggers.create && !triggers.update">
      <div class="col grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
               <table id="personal" class="table">
                <thead>
                  <tr>
                    <th>DNI</th>
                    <th>Legajo</th>
                    <th>Nombre</th>
                    <th>Empleado por</th>
                    <th>Ingreso</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="personal in personal" v-bind:key="personal.config.fireId">
                    <td>{{personal.leg_numdoc}}</td>
                    <td>{{personal.leg_numero}}</td>
                    <td>{{personal.leg_nombre}} <br> {{personal.leg_apellido}}</td>
                    <td><div class="badge w-75" :class="'badge-'+personal.config.db_style">{{personal.config.db_label}}</div></td>
                    <td :data-sort="personal.leg_fecing">{{dateFormat(personal.leg_fecing.date)}}</td>
                    <td>
                      <button v-if="isEditor('Personal')==true" type="button" class="btn btn-primary" @click.prevent="handleSelect(personal)">Administrar</button>
                      <button v-else type="button" class="btn btn-primary" disabled>Administrar</button>
                    </td>
                  </tr>
                </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>

  </div>
  </div>
</template>

<script>

import axios from 'axios';
import $ from 'jquery';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

import AltaPersonal from './AltaPersonal';
import UpdatePersonal from './UpdatePersonal';
import ListaCarga from '../../components/admin/ListaCarga.vue';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: 'Nomina',
    components: {
      AltaPersonal,
      UpdatePersonal,
      ListaCarga
    },
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Nómina',
            descripcion: 'En este modulo usted podra visualizar y administrar todo el personal de la empresa. Además cuenta con la posibilidad de dar de alta y baja al personal',
            triggers: {load:true,create:false,update:false},
            personal: [],
            selected: null,
            base_de_datos:[
                {label:'FS América',name:'personal',style:'america'},
                {label:'FS Catering',name:'personal_cat',style:'catering'},
                {label:'Externos',name:'personal_ext',style:'externos'},
                {label:'Alimentación',name:'personal_ali',style:'alimentacion'},
            ],
        }
    },
    methods: {
        handleSelect(entity){
          console.log('update');
          this.selected = entity;
          this.triggers.update = true;
        },
        isEditor(modulo){
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        obtenerPersonal(){
            this.personal = [];
            this.triggers.load = true;
            let promises = this.base_de_datos.map((db)=>{
                return axios.get(`https://apprrhh-707b9.firebaseio.com/${db.name}.json`).then((response)=>{
                    if(response.data!=null) {
                      Object.keys(response.data).forEach((fireId)=>{
                        if (response.data[fireId].leg_fecegr != undefined && response.data[fireId].leg_fecegr.date != "") return; //Saltea los dados de baja
                        this.personal.push(Object.assign(response.data[fireId],{config:{
                          fireId: fireId,
                          db_name: db.name,
                          db_label: db.label,
                          db_style: db.style
                        }}));
                      })
                      // Object.assign(this.personal,response.data);
                    } 
                }).catch((err)=>this.errorLog(err,'Hubo un error al obtener información. Presione CANCELAR para reintentar o ACEPTAR para recargar la página.'));
            });

            Promise.all(promises).then(()=>{
                this.triggers.load = false;
                this.$nextTick(()=>{
                    this.inicializarTabla();
                })
            });  
        },
        refrescarPersonal(seReasigno) {
          this.triggers.create = false;
          this.triggers.update = false;

          console.log('llega a refrescar, refrescará?', seReasigno);

          if (seReasigno) {
            this.obtenerPersonal();
          }else {
            this.$nextTick(()=>{
              this.inicializarTabla();
            })
          }
        },
        errorLog(e,msg){
            console.error(e);
            if (window.confirm(msg)) {
                location.reload();
            }
        },
        inicializarTabla(){
            $("#personal").dataTable().fnDestroy();
            $("#personal").DataTable({
                language: {
                decimal: ",",
                thousands: ".",
                info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
                infoPostFix: "",
                infoFiltered: "(filtrado de un total de _MAX_ registros)",
                loadingRecords: "Cargando...",
                lengthMenu: "Mostrar _MENU_ registros",
                paginate: {
                    first: "Primero",
                    last: "Último",
                    next: "Siguiente",
                    previous: "Anterior",
                },
                processing: "Procesando...",
                search: "Buscar:",
                searchPlaceholder: "Término de búsqueda",
                zeroRecords: "No se encontraron resultados",
                emptyTable: "Ningún dato disponible en esta tabla",
                aria: {
                    sortAscending:
                    ": Activar para ordenar la columna de manera ascendente",
                    sortDescending:
                    ": Activar para ordenar la columna de manera descendente",
                },
                },
                scrollX: false,
                search: { regex: true },
                dom: "Bfrtip",
                responsive: false,
                nowrap: false,
                buttons: ["copy", "excel", "csv", "pdf"],
            });
        },
        dateFormat(initDate,includeYear){
            let date = new Date(initDate)

            let day = date.getDate()
            let month = date.getMonth() + 1
            let year;
            if (includeYear==undefined) year = date.getFullYear()

            if(month < 10){
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-0${month}`) : (`0${day}-0${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-0${month}`) : (`${day}-0${month}-${year}`)
            }else{
                if(day<10){
                    return (includeYear!=undefined && !includeYear) ? (`0${day}-${month}`) : (`0${day}-${month}-${year}`)
                }
                return (includeYear!=undefined && !includeYear) ? (`${day}-${month}`) : (`${day}-${month}-${year}`)
            }
        }
    },
    mounted() {
        this.obtenerPersonal();
    },
};
</script>

<style scoped>
.badge-catering {
    color: #212529;
    background-color: #ffb220;
}

.badge-america {
    color: #fff;
    background-color: #196ff5;
}

.badge-alimentacion {
    color: #fff;
    background-color: #19b7f5;
}

.badge-externos {
    color: #212529;
    background-color: #b4b4b4;
}
</style>