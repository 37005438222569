<template>
  <div class="wrapper-input">
    <input type="file" accept="image/*" ref="input" @change="seleccion()"/>
    <!--Preview success box -->
    <div class="wrapper-preview" v-if="file">
      <span class="text-success mt-2"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-success" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg> Seleccionado</span>
      <img :src="preview" class="preview-thumb" v-if="preview" @click="modalPreview=true">
    </div>
    <!-- Action buttons -->
    <div class="d-flex">
      <div class="btn btn-md" :class="{'btn-success':file, 'w-75': file, 'w-100': !file, 'btn-danger':error, 'btn-primary':!error}" @click="select()">
        <!-- <svg class="icon" v-if="!file" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" ></path> <polyline points="14 2 14 8 20 8"></polyline> <line x1="12" y1="18" x2="12" y2="12"></line> <line x1="9" y1="15" x2="15" y2="15"></line> </svg> -->
        <slot v-if="!file"><svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg></slot>
        <svg class="icon" v-else viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="1 4 1 10 7 10"></polyline><path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path></svg>
        Reemplazar {{titulo}}
      </div>
      <div class="btn btn-danger w-25 ml-1 px-0" v-if="file" @click="removeImage()">
        <svg class="icon" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
      </div>
    </div>
    <div class="text-danger mt-1" v-if="error">&otimes; {{error}}</div>
  </div>
   <transition name="modal" v-if="modalPreview">
        <div class="modal-mask" @click="modalPreview=false">
            <div class="modal-wrapper w-100" >
                <div class="modal-container">
                    <div class="modal-body">
                        <img :src="preview" @click="modalPreview=false"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: "InputFile",
  props: ['titulo','error'],
  data() {
    return {
      file: null,
      shortName: null,
      preview: null,
      modalPreview:false,
    };
  },
  methods: {
    select() {
      this.$refs.input.click();
    },
    createImage(file) {
      let reader = new FileReader()
      reader.onload = (event) => {
          this.preview = event.target.result
      }
      reader.readAsDataURL(file)
    },
    seleccion(){
        this.file = this.$refs.input.files[0];
        this.shortName = this.filterName(this.$refs.input.files[0].name,15);
        this.createImage(this.$refs.input.files[0]);
        this.$emit('seleccion',this.file);
    },
    removeImage(){
        this.file = null;
        this.shortName = null;
        this.$emit('imagen-removida');
    },
    /**
     * Recorta un string si su longitud es mayor a la longitud dada 
     * agrega ".." al inicio en caso de realizar el corte
     * @param str es el string a recortar
     * @param chars la longitud del string limite
     * @return un string recortado o no
     */
    filterName(str,chars){
        if (str.length > chars) {
            return ".." + str.slice(str.length-chars);
        }else {
            return str
        }
    }
  },
};
</script>

<style>


.wrapper-input {
  display: flex;
  flex-flow: column;
  /* margin-right: .4rem; */
}

.preview-thumb {
  margin-top: 6px;
  width: 100%;
  height: 140px;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
  cursor:pointer
}

input[type="file"] {
  display: none;
}

.icon {
    width: 16px;
    height: 16px;
    stroke: #fff;
}

.icon-success {
    stroke: #10b759;
}

.icon-remove {
    stroke: #ff3366;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

/* .remove-button {
    margin-left: .4rem;
} */

.wrapper-preview {
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.1875rem;
    border: 1px solid #000;
    font-size: 0.875rem;
    line-height: 1;
    padding: .5rem 1rem .4rem;
    margin-bottom: 0.4rem;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  cursor: zoom-out;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 60%;
  margin: 0px auto;
  transition: all .3s ease;
}

.modal-body {
  text-align: center;
}

.modal-body img {
  max-width: 110%;
  max-height: 568px;
}

.modal-default-button {
  /* float: right; */
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
