<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header align-items-center no-gutters">
            <div class="col-10">
              Administrar empleados en {{ nombreEmpresa }}
            </div>
            <div class="col-2 text-right">
              <button
                type="button"
                class="btn btn-sm btn-light font-weight-bold"
                @click.prevent="close()"
              >
                x
              </button>
            </div>
          </div>
          <div class="modal-body my-0" v-if="!success">
            <!-- Form -->
            <label for="selectEmpleado" class="form-label"
              >Añadir empleado</label
            >
            <div class="row align-items-center mb-4">
              <!-- Select -->
              <div class="col-10">
                <!-- <Select2 class="w-100" v-model="legajo" :options="empleados" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" /> -->
                <Select2
                  class="w-100 input-error"
                  v-model="legajo"
                  :options="empleados"
                  :settings="{
                    settingOption: value,
                    settingOption: value,
                    width: '100%',
                    theme: 'classic',
                  }"
                  @select="guardarSeleccion($event)"
                />
              </div>
              <!-- Button Add -->
              <div class="col-2">
                <button
                  type="button"
                  class="btn btn-success w-100"
                  @click="asignarEmpleado()"
                >
                  Añadir
                </button>
              </div>
              <!-- Add error -->
              <p class="text-danger pl-3" v-if="errors.nombre">
                · {{ errors.nombreMensaje }}
              </p>
            </div>

            <!-- <Select2 v-model="legajo" :options="empleados" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
                            <h4>Value: {{ legajo }}</h4> -->
            <label for="listaEmpleados" class="form-label"
              >Empleados en la operación</label
            >

            <!-- Loader -->
            <lista-carga v-if="triggers.carga" :filas="3"></lista-carga>
            <!-- <div v-else-if="empleadosEnOperacion == null" class="alert alert-primary" role="alert">
                                No hay empleados asignados
                            </div> -->

            <!-- Table -->
            <div v-else class="table-responsive">
              <table id="empleados" class="table">
                <thead>
                  <tr>
                    <th>Legajo</th>
                    <th>Nombre</th>
                    <th>Puesto</th>
                    <th class="text-center">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="empleado in empleadosEnOperacion"
                    v-bind:key="empleado.legajo"
                  >
                    <td>{{ empleado.legajo }}</td>
                    <td>
                      {{ empleado.nombre }} <br />
                      {{ empleado.apellido }}
                    </td>
                    <td>
                      <div class="spinner" v-if="!empleado.categoria"></div>
                      {{ empleado.categoria }}
                    </td>
                    <td class="text-right">
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        @click.prevent="removerEmpleado(empleado.legajo)"
                      >
                        Quitar de operación
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Buttons footer -->
          <div class="modal-footer" v-if="!success">
            <button
              type="button"
              class="btn btn-light"
              @click.prevent="close()"
            >
              Cancelar
            </button>
            <button
              type="button"
              @click.prevent="close()"
              class="btn btn-success"
            >
              Terminar
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";
import firebase from "firebase/app";
import "firebase/database";
import ListaCarga from "../ListaCarga.vue";

import $ from "jquery";

require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();

export default {
  components: { Select2, ListaCarga },
  // components: {Select2},
  name: "ModalAdministrar",
  props: ["empresa", "nombreEmpresa"],
  data() {
    return {
      legajo: "",
      dni: "",
      nombre: "",
      errors: {
        nombre: false,
        nombreMensaje: null,
      },
      triggers: {
        carga: true,
        sinEmpleados: false,
        empleadosConCategorias: false,
      },
      success: false,
      empleadosEnOperacion: [],
      empleados: [],
      empleadosFullData: [],
      distribucion: [],
      categorias: [],
      categoriasAsignadas: false,
      seReasigno: false,
      enLegales: [],
    };
  },
 async mounted()  {
    // Obtener personal

const axiosrequest1 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty');
const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');

     await axios.all([axiosrequest1, axiosrequest2]).then(
            axios.spread((personal,personal_cat) => {
        if (personal.status == 200) {
          axios
            .get("https://aplicativos.firebaseio.com/legales.json?print=pretty")
            .then((legalResponse) => {
              const legales = legalResponse.data;
              const data = Object.assign({}, personal.data, personal_cat.data);
              
              // Para usar en otras operaciones, como obtener el DNI por ejemplo.
              this.empleadosFullData = data;
              /**
               *  Select2 requiere un array [{id:1,text:nombre}]
               * 	Se mapea el response a traves de las claves del objeto JSON
               */
              this.empleados = Object.keys(data).flatMap((key) => {
                if (legales[data[key].leg_numdoc] != null) {
                  this.enLegales.push(data[key].leg_numero);
                  return [];
                } else {
                  return {
                    id: data[key].leg_numero,
                    text: data[key].leg_apellido + " " + data[key].leg_nombre,
                  };
                }
              });
            });
        }
       })).then(() => {
        setTimeout(() => {
          this.obtenerDistribucionActual();
          this.obtenerDistribucionGeneral();
          this.listarEmpleados();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    listarEmpleados() {
      this.$nextTick(() => {
        $("#empleados").dataTable({
          language: {
            decimal: ",",
            thousands: ".",
            info:
              "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            infoEmpty:
              "Mostrando registros del 0 al 0 de un total de 0 registros",
            infoPostFix: "",
            infoFiltered: "(filtrado de un total de _MAX_ registros)",
            loadingRecords: "Cargando...",
            paginate: {
              first: "Primero",
              last: "Último",
              next: "Siguiente",
              previous: "Anterior",
            },
            processing: "Procesando...",
            search: "Buscar:",
            searchPlaceholder: "Término de búsqueda",
            zeroRecords: "No se encontraron resultados",
            emptyTable: "Ningún dato disponible en esta tabla",
            aria: {
              sortAscending:
                ": Activar para ordenar la columna de manera ascendente",
              sortDescending:
                ": Activar para ordenar la columna de manera descendente",
            },
          },

          lengthMenu: [3],
          search: { regex: true },
          dom: "Bfrtip",
          responsive: true,
          nowrap: false,
        });
      });
    },
    guardarSeleccion({ id, text }) {
        console.log(text);
        this.legajo = id;
        this.nombre = text;
        this.errors.nombre = false;
        this.comprobarEmpleadoEnDistribucionActual();
    },
    limpiarSeleccion() {
      this.legajo = "";
      this.nombre = "";
    },
    asignarEmpleado() {
      // Comprobar que se selecciono un empleado
      if (!this.legajo) {
        this.errors.nombre = true;
        this.errors.nombreMensaje = "Antes de añadir selecciona un empleado";
        return null;
      }

      // Comprobar que no existe el empleado
      if (this.comprobarEmpleadoEnDistribucionActual()) {
        return null;
      }

      // Mostramos el loader, se desactiva una vez que hayan categorias
      this.triggers.carga = true;

      // console.log({
      //     empresa: this.empresa,
      //     leg: this.legajo,
      //     nombre: this.nombre,
      // });

      this.removerEmpleado(this.legajo);

      console.log("pusheas al empleado en", "distribucion/" + this.empresa);

      // Pushea el empleado seleccionado en la empresa seleccionada
      firebase
        .database()
        .ref("distribucion/" + this.empresa)
        .push(
          {
            legajo: this.legajo,
            nombre: this.nombre,
          },
          (error) => {
            if (error) {
              alert(
                "error: no se pudo asignar el empleado en la operacion /n verificá la conexión a internet o reintentalo"
              );
            } else {
              console.log(
                "empleado asignado asignado a la operación: " + this.empresa
              );
              this.seReasigno = true;
            }
          }
        );

      $("#empleados") .dataTable() .fnDestroy();
      this.obtenerDistribucionActual();
      this.obtenerDistribucionGeneral();
      this.listarEmpleados();
      this.limpiarSeleccion();
    },
    removerEmpleado(legajo) {
      // Obtiene la distribucion actual de empleados
      // this.obtenerDistribucionGeneral();
      // this.triggers.carga = true;
      // console.log("removiendo empleado con el legajo: " + legajo);
      $("#empleados") .dataTable() .fnDestroy();
      // Busca en operaciones al empleado para removerlo de otra operacion/distribucion
      Object.keys(this.distribucion).forEach((operacion) => {
        // console.log("Opereta nro: " + operacion);

        // fireaplicativo.database().ref('distribucion/'+operacionId+'/'+codigoId).remove();
        Object.keys(this.distribucion[operacion]).forEach((distribucion) => {
          // console.log(legajo == this.distribucion[operacion][distribucion].legajo);

          if (legajo == this.distribucion[operacion][distribucion].legajo) {
            console.log(this.distribucion[operacion][distribucion].nombre + " pertenece a la operacion " + operacion);
            console.log("distribucion/" + operacion + "/" + distribucion);

            firebase
              .database()
              .ref("distribucion/" + operacion + "/" + distribucion)
              .remove()
              .then(() => {
                // distribucion/000555/-MGEHtQVl8n-6xq3ToqD
                $("#empleados") .dataTable() .fnDestroy();
                this.$nextTick(() => {
                  this.obtenerDistribucionActual();
                  this.obtenerDistribucionGeneral();
                  this.listarEmpleados();
                });

                this.seReasigno = true;
                console.log("Empleado removido de la operacion: " + operacion);
              })
              .catch((error) => {
                if (error) {
                  console.log(error);
                }
            });
          }
        });
      });
    },
    obtenerDistribucionActual() {
      this.triggers.carga = true;
      this.empleadosEnOperacion = [];
      axios
        .get(
          "https://aplicativos.firebaseio.com/distribucion/" +
            this.empresa +
            ".json?print=pretty"
        )
        .then((response) => {
          if (response.status == 200 && response.data != null) {
            // console.log(response.data);
            // axios.get("https://aplicativos.firebaseio.com/legales.json?print=pretty")
            // .then(legalResponse=>{
            //     const legales = legalResponse.data;
            //     // console.log(legales);

            // })

            const data = response.data;

            this.empleadosEnOperacion = Object.keys(data).flatMap((key) => {
              // console.log(data[key].legajo);
              // console.log("esta? " + this.estaEnLegales(data[key].legajo));
              if (this.estaEnLegales(data[key].legajo)) {
                return [];
              } else {
                return data[key];
              }
            });

            // this.empleadosEnOperacion = response.data;
            // Obtener las categorias para poder asignarlas a los empleados
            axios
              .get(
                "https://aplicativos.firebaseio.com/categorias.json?print=pretty"
              )
              .then((response) => {
                if (response.status == 200) {
                  // console.log(response.data);
                  this.categorias = response.data;
                }
              });
            setTimeout(() => {
              this.asignarCategorias();
            }, 1500);
          } else {
            this.triggers.carga = false;
            this.listarEmpleados();
          }
        })
        .catch((e) => {
          alert(
            "Se produjo un error al obtener la distribucion de la empresa con id: " +
              this.empresa
          );
          console.log(
            "error al obtener la distribución con id: " + this.empresa
          );
        });
    },
    obtenerDistribucionGeneral() {
      axios
        .get(
          "https://aplicativos.firebaseio.com/distribucion.json?print=pretty"
        )
        .then((response) => {
          if (response.status != 200)
            console.log(
              "Hubo un error al obtener la distribucion general de empleados"
            );
          this.distribucion = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    asignarCategorias() {
      // Si existen empleados busca las categorias por cada legajo
      if (!(this.empleadosEnOperacion == null)) {
        Object.keys(this.empleadosEnOperacion).forEach((empleado) => {
          this.buscarCategoriaPorLegajo(
            this.empleadosEnOperacion[empleado].legajo,
            empleado
          );
        });
      }
      // Si encontro categorias o no actuliza la tabla, mostrando los empleados o no
      this.triggers.carga = false;
      $("#empleados")
        .dataTable()
        .fnDestroy();
      this.$nextTick(() => {
        this.listarEmpleados();
      });
    },
    close() {
      this.$emit("cerrar-modal-asignar", this.seReasigno);
    },
    buscarCategoriaPorLegajo(legajo, empleado) {
      Object.keys(this.empleadosFullData).forEach((empleadoFull) => {
        if (this.empleadosFullData[empleadoFull].leg_numero == legajo) {
          // console.log('Lo encontré y se llama: ' + this.empleadosFullData[empleadoFull].cat_codigo);
          Object.keys(this.categorias).forEach((cat) => {
            if (
              this.categorias[cat].codigo ==
              this.empleadosFullData[empleadoFull].cat_codigo
            ) {
              // Asigna al empleado la categoria
              this.empleadosEnOperacion[empleado].categoria = this.categorias[
                cat
              ].nombre;
              // console.log(this.categorias[cat].nombre);
            }
          });
        }
      });
    },
    comprobarEmpleadoEnDistribucionActual() {
      if (this.empleadosEnOperacion == null) {
        return false;
      }

      Object.keys(this.empleadosEnOperacion).forEach((firebaseId) => {
        if (this.empleadosEnOperacion[firebaseId].legajo == this.legajo) {
          this.errors.nombre = true;
          this.errors.nombreMensaje =
            "El empleado seleccionado ya existe en la operación.";
          return true;
        }
      });

      return false;
    },
    estaEnLegales(legajo) {
      let esta = false;
      Object.keys(this.enLegales).forEach((item) => {
        if (this.enLegales[item] == legajo) esta = true;
      });

      return esta;
    },
  },
};
</script>

<style scoped>
h3 {
  color: #1ea21e;
}

.input-error {
  border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px #00000016;
  transition: all 0.3s ease;
  font-size: 16px;

  /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
  margin-bottom: 1rem;
}

input,
select {
  font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
