<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ entidad }}
        </li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ entidad }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">{{ descripcion }}</p>
              <button
                v-on:click="crear()"
                type="button"
                class="btn w-25"
                :class="{
                  'btn-light': trigger.crear,
                  'btn-success': !trigger.crear,
                }"
              >
                {{
                  !trigger.crear
                    ? `Crear Pregunta`
                    : "Cancelar la creación"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Crear -->
    <component-place v-if="trigger.crear">
      <crear-post @cerrar="cerrar('crear',$event)" ></crear-post>
    </component-place>
    <!-- Ver -->
    <component-place v-if="trigger.ver">
      <return :align="right" @cerrar="cerrar('ver',false)"></return>
      <ver-post :post="seleccion"></ver-post>
    </component-place>
    <!-- Listar -->
    <component-place v-if="trigger.listar">
      <listar-posts :posts="posts" @mostrar="manipularPost('ver',$event)" @editar="manipularPost('editar',$event)"></listar-posts>
    </component-place>
    <!-- Editar -->
    <component-place v-if="trigger.editar">
      <return :align="right" @cerrar="cerrar('editar',false)"></return>
      <editar-post :post="seleccion" @cerrar="cerrar('editar',$event)"></editar-post>
    </component-place>
  </div>
</template>

<script>
import { api } from "@/env";
import axios from "axios";

import ComponentPlace from '@/components/admin/ComponentPlacePreguntas'
import Return from '@/components/admin/ReturnButton'

import CrearPost from '@/components/admin/preguntas/forms/CrearPreguntas'
import ListarPosts from '@/components/admin/preguntas/ListarPreguntas'
import VerPost from '@/components/admin/preguntas/VerPreguntas'
import EditarPost from '@/components/admin/preguntas/forms/EditarPreguntas'
console.log(ListarPosts)

export default {
  name: "Preguntas",
  components: {ComponentPlace,Return,CrearPost,ListarPosts,VerPost,EditarPost},
  data() {
    return {
      breadcrumbA: "Inicio",
      descripcion: `Lista de todos las preguntas frecuentes. Crea, modifica y elimina su contenido`,
      entidad: "Preguntas Frecuentes | Mi Food Service",
      posts: [],
      // Para modificacion
      seleccion: null,
      trigger: {
        crear: false,
        editar: false,
        listar: true,
        ver:false
      },
      imagenData: {
        title:'',
        url:'',
        explanation:''
      },
    };
  },
  methods: {
    obtenerEntidades() {
      this.posts = [];
      console.log(api.preguntas)
      axios.get(api.preguntas).then((postsResp) => {

          if (postsResp.data) {
              this.posts = Object.keys(postsResp.data).map((fireId) => {
                // Se agrega el Fireid como propiedad para trabajar luego
                let post = postsResp.data[fireId];
                post.fireId = fireId;
                return post;
              });
          }
        this.trigger.listar = true;
      });
    },
    crear() {
      // Si el componente se muestra
      if (!this.trigger.crear) {
        this.trigger.crear = true;
        this.trigger.listar = false;
      }else {
        this.trigger.crear = false;
        this.trigger.listar = true;
      }
    },
    manipularPost(accion,post){
      this.seleccion = post
      this.trigger.listar = false
      this.trigger[accion] = true
    },
    cerrar(accion,actualizar) {
      this.trigger[accion] = false;
      this.seleccion = null;
      if (actualizar) {
        this.obtenerEntidades();
      } else {
        this.trigger.listar = true;
      }
    },
  },
  mounted() {
    this.obtenerEntidades();
  },
};
</script>

<style></style>