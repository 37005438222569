<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>
                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                  <th>Perfil</th>
                                  
                                  <th>Legajo</th>
                                  <th>Nombre</th>
                                 
                                  <th>Empleado por</th>
                                  <th>Ingreso</th>
                                  <th>Categoria</th>
                                 
                                 
                                  <th>Personal a cargo</th>

                                  <th>NETO <span id="mes"></span> 2022</th>
                                 
                                 
                                  
                                </tr>
                              </thead>
                              <tbody>
                                
                                <tr v-for="personal in personal" v-bind:key="personal" :class="{'fila-no-distribuido':personal.cod=='-'}" >
                                   
                                    <td v-if="personal.avatar=='-'" style="color:#fff;">2<img width="40px" height="40px" style="border: 1px solid #8b8b8b99;object-fit:cover" src="https://thumbs.dreamstime.com/b/perfil-de-usuario-vectorial-avatar-predeterminado-179376714.jpg"></td>
                                    <td v-else style="color:#fff;">1<img width="40px" height="40px" style="object-fit:cover" :src="personal.avatar"></td>


                                   
                                    <td>{{personal.legajo}}</td>
                                    <td>{{personal.apellido}} <br> {{personal.nombre}}</td>

                                  

                                  

                                      <td v-if="personal.razonsocial=='Food Service America'"><span class="badge badge-success">Food Service America</span></td>
                                    <td v-else><span class="badge badge-info">Food Service Catering</span></td>


                                    


                                    <td>{{personal.ingreso}}</td>
                                    <td>{{personal.categoria}}</td>
                                   
                                   
                                    <td>{{personal.zonal}}</td>
                                    <td>${{personal.salario}}</td>
                              
                                   
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                <modal-administrar
                    v-if="modalAdministrar.open" 
                    :empresa="modalOperacionId"
                    :legajoEmpleado="modalAdministrar.legajo"
                    :nombreEmpleado="modalAdministrar.nombre"
                    :apellidoEmpleado="modalAdministrar.apellido"
                    :operacion="modalAdministrar.operacion"
                    :idDistribucion="modalAdministrar.idDistribucion"
                    @cerrar-modal-asignar="refrescarPersonal($event)"
                >
                </modal-administrar>


        <modal-observar
            v-if="modalObservar.open" 
                    :dniEmpleado="modalObservar.dni"
                    :legajoEmpleado="modalObservar.legajo"
                    :nombreEmpleado="modalObservar.nombre"
                    :apellidoEmpleado="modalObservar.apellido"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalObservar.open=false"
        ></modal-observar>


         <modal-vacunas
            v-if="modalVacunas.open" 
                    :dniEmpleado="modalVacunas.dni"
                    :legajoEmpleado="modalVacunas.legajo"
                    :nombreEmpleado="modalVacunas.nombre"
                    :apellidoEmpleado="modalVacunas.apellido"
                    :certificadoEmpleado="modalVacunas.certificado"
                   
            @cerrar-modal-observar="refrescarPersonal($event)"
            v-on:keyup.enter="modalVacunas.open=false"
        ></modal-vacunas>

                <modal-legales
                    v-if="modalLegales.open" 
                    :dniEmpleado="modalLegales.dni"
                    :nombreEmpleado="modalLegales.nombre"
                    :apellidoEmpleado="modalLegales.apellido"
                    :legajoEmpleado="modalLegales.legajo"
                    @cerrar-modal-legales="refrescarPersonal($event)"
                ></modal-legales>

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import modalVacunas from './ModalVacunas.vue';
import ModalLegales from './ModalLegales';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: 'Personal',
    components: {ListaCarga, ModalAdministrar, ModalLegales,ModalObservar,modalVacunas},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Personal',
            descripcion: 'En este modulo usted podra visualizar y administrar todo  el personal de la empresa',
            triggers: {load:true},
            personal: [],
             modalObservar: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
            },
             modalVacunas: {
                open: false,
                dni: null,
                nombre: null,
                legajo: null,
                apellido: null,
                certificado:null
            },
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {
        isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        observarPersonalId(dni, nombre, apellido, legajo){
            this.modalObservar.dni = dni;
            this.modalObservar.nombre = nombre;
            this.modalObservar.apellido = apellido;
            this.modalObservar.legajo = legajo;

            console.log(apellido)
            setTimeout(() => {
                this.modalObservar.open = true;
            }, 200);
        },
        vacunas(dni, nombre, apellido, legajo,certificado){
            

            this.modalVacunas.dni = dni;
            this.modalVacunas.nombre = nombre;
            this.modalVacunas.apellido = apellido;
            this.modalVacunas.legajo = legajo;

            if (certificado!=null) {
                this.modalVacunas.certificado = certificado;
            }
            

            console.log(apellido)
            setTimeout(() => {
                this.modalVacunas.open = true;
            }, 200);
        },
        abrirModalAdministrar(legajo,nombre,apellido,operacion,idDistibucion,empleade){
            console.log(empleade);

            this.modalAdministrar.legajo = legajo;
            this.modalAdministrar.nombre = nombre;
            this.modalAdministrar.apellido = apellido;
            this.modalAdministrar.operacion = (operacion == '-') ? undefined : operacion;
            this.modalAdministrar.idDistribucion = idDistibucion;
            this.modalAdministrar.open = true;
        },
        abrirModalLegales(dni,nombre,apellido,legajo){
            this.modalLegales.dni = dni;
            this.modalLegales.nombre = nombre;
            this.modalLegales.apellido = apellido;
            this.modalLegales.legajo = legajo;
            this.modalLegales.open = true;
        },
        async obtenerPersonal(){

                var cat = "";
                var am = "";
                var mes = "";

            var fecha1 = new Date();
           
            var mex = fecha1.getMonth() + 1;
            var mesex = mex < 10 ? "0" + mex : mex;
            if (mesex=="07") {
               
                mes = "JULIO";
            }

            if (mesex=="08") {
                
                mes = "JULIO";
            }



            this.triggers.load = true;
            const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
            const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty');
            const axiosrequest3 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
            const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
            const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
            const axiosrequest6 = axios.get('https://aplicativos.firebaseio.com/legales.json?print=pretty');
            const axiosrequest7 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');

            const axiosrequest8 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');
            const axiosrequest9 = axios.get('https://aplicativos.firebaseio.com/observacion_personal.json?print=pretty');
            const axiosrequest10 = axios.get('https://aplicativos.firebaseio.com/manipulacion.json?print=pretty');
            const axiosrequest11 = axios.get('https://aplicativos.firebaseio.com/certificado_vacunacion.json?print=pretty');
            const axiosrequest12 = axios.get('https://aplicativos.firebaseio.com/vacunas_personal.json?print=pretty');
            const axiosrequest13 = axios.get('https://aplicativos.firebaseio.com/perfil.json?print=pretty');

            const axiosrequest14 = axios.get('https://auth.foodservice.com.ar/?type=liq_fs_catering&liquidacion=49&access_token=1234567');
            const axiosrequest15 = axios.get('https://auth.foodservice.com.ar/?type=liq_fs_america&liquidacion=602&access_token=1234567');

            await axios.all([axiosrequest1, axiosrequest2, axiosrequest3, axiosrequest4, axiosrequest5, axiosrequest6, axiosrequest7,axiosrequest8,axiosrequest9,axiosrequest10,axiosrequest11,axiosrequest12,axiosrequest13,axiosrequest14,axiosrequest15]).then(
            axios.spread((operaciones, personal, distribucion, zonal, categorias, legales, sectores,personal_cat,observaciones,manipulacion,vacunacion,dosis,avatar,liquidacion_cat,liquidacion_am) => {
                var lista = [];
                var observacion = false;

                
                 $.each(liquidacion_cat.data, function(clave, valor) {
                    localStorage.setItem(clave,valor.datos.neto);
                 });

                  $.each(liquidacion_am.data, function(clave, valor) {
                    localStorage.setItem(clave,valor.datos.neto);
                 });



                const result_categoria = (id) => {
                    var categoria = "";
                    $.each(categorias.data, function(clave, value) {

                        if (value.codigo == id) {
                            categoria = value.nombre;
                        }
                    })
                    return categoria;
                }

                const result_distribucion = (id) => {
                    var distri = "";
                    $.each(distribucion.data, function(clave, value) {
                        $.each(value, function(k, v) {
                            if (v.legajo == id) {
                                distri = {
                                    operacion: clave,
                                    idDistribucion: k
                                }                               
                            }
                        })

                    });
                    return distri;
                }

                const result_operacion = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].operacion;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.name;
                             
                            }
                        })
                    }
                    return operacion;
                }




                const result_madre = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "-";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.empresa_madre;
                             
                            }
                        })
                    }
                    return operacion;
                }




const result_tipo = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = "Planta Central";
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.tipo_empresa;
                             
                            }
                        })
                    }
                    return operacion;
                }



                const result_operacion_bejerman = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].id;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.catering_id;
                             
                            }
                        })
                    }
                    return operacion;
                }


                 const result_apropiacion = (id) => {
                    var operacion = "";
                    var apropiacion = "";
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                          
                            apropiacion = sectores.data[sector].id_empresa;

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                             
                                // apropiacion = value.centroapropiacion_id
                                apropiacion = value.id
                            }
                        })
                    }
                    return apropiacion;
                }

                const result_zonal = (id) => {
                    var zonales = "";
                    $.each(zonal.data, function(clave, value) {
                        if (clave == id) {
                            zonales = value.nombre;
                        }
                    })
                    return zonales;
                }


                 const result_manipulador = (dni) => {
                    var manip = "";
                    $.each(manipulacion.data, function(clave, value) {
                        if (clave == dni) {
                            manip = value.link;
                        }
                    })
                    return manip;
                }

                const result_vacunacion = (legajo) => {
                    var vacu = "";
                    $.each(vacunacion.data, function(clave, value) {
                        if (clave == legajo) {
                            vacu = value.certificado;
                        }
                    })
                    return vacu;
                }

                const result_dosis = (dni) => {
                    var dos = "";
                    $.each(dosis.data, function(clave, value) {
                        if (clave == dni) {
                            // console.log("datos obtenidos!")
                           dos = Object.keys(value).length/2
                        }
                    })
                    return dos;
                }


                const result_avatar = (dni) => {
                    var avtr = "";
                    $.each(avatar.data, function(clave, value) {
                        if (clave == dni) {
                            avtr = value.imagen;
                        }
                    })
                    return avtr;
                }

                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }

                const reemplazarIndefinidoDosis = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "0"
                    }
                    return string
                }




var cant_personal=[];

 $.each(personal.data, function(cla, val) {

                   let distribucion = result_distribucion(val.leg_numero);

                    if (val.leg_fecegr == null) {
                     
                          if (distribucion.operacion!='999991') {
                            cant_personal.push(reemplazarIndefinido(result_zonal(result_distribucion(val.leg_numero).operacion)))
                            }

                       
                    }
                });

               $.each(personal_cat.data, function(cla, val) {

                   let distribucion = result_distribucion(val.leg_numero);

                    if (val.leg_fecegr == null) {
                     
                          if (distribucion.operacion!='999991') {
                            cant_personal.push(reemplazarIndefinido(result_zonal(result_distribucion(val.leg_numero).operacion)))
                            }

                       
                    }
                });

                
                console.log(cant_personal)

                 const contadorElemento =(matrizValores, busqueda) => {
                      let acumulador = 0;
                      
                      
                      for (let i = 0; i<matrizValores.length; i++) {
                       
                           
                          if (matrizValores[i] === busqueda) {
                            acumulador++;
                          }
                        
                      }


                      return acumulador;
                    }





               
                $.each(personal.data, function(clave, valor) {

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                     
                    if(legales.data[valor.leg_numdoc] != null) return true;
                    
                    if (valor.leg_fecegr == null) {
                        
                        let distribucion = result_distribucion(valor.leg_numero);
                         if (distribucion.operacion=='999991') {
                            var nombre_zonal=valor.leg_apellido+" "+valor.leg_nombre;
                            var basico = localStorage.getItem(valor.leg_numero);
                          
                            lista.push({
                                "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                                "razonsocial": "Food Service America",
                                "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                                "legajo": reemplazarIndefinido(valor.leg_numero),
                                "nombre": reemplazarIndefinido(valor.leg_nombre),
                                "apellido": reemplazarIndefinido(valor.leg_apellido),
                                "ingreso": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                                "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                                "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                                 "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                                "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                                "idDistribucion": distribucion.idDistribucion,
                                "zonal": contadorElemento(cant_personal,nombre_zonal),
                                "dni": valor.leg_numdoc,
                                "observacion":observacion,
                                "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                                "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                                "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                                "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                                "salario": basico,
                            })
                        }
                    }
                });

                $.each(personal_cat.data, function(clave, valor) {

                     observacion = (observaciones.data[valor.leg_numdoc] != undefined) ? true : false;
                    if(legales.data[valor.leg_numdoc] != null) return true;

                    if (valor.leg_fecegr == null) {
                        let distribucion = result_distribucion(valor.leg_numero);

                         if (distribucion.operacion=='999991') {
                            var nombre_zonal=valor.leg_apellido+" "+valor.leg_nombre;
                            var basico = localStorage.getItem(valor.leg_numero);
                        lista.push({
                            "cod": reemplazarIndefinido(result_apropiacion(distribucion.operacion)),
                            "razonsocial": "Food Service Catering",
                            "bejerman": reemplazarIndefinido(result_operacion_bejerman(distribucion.operacion)),
                            "legajo": reemplazarIndefinido(valor.leg_numero),
                            "nombre": reemplazarIndefinido(valor.leg_nombre),
                            "apellido": reemplazarIndefinido(valor.leg_apellido),
                            "ingreso": reemplazarIndefinido(valor.leg_fecingr.date.replace(" 00:00:00.000000", "")),
                            "categoria": reemplazarIndefinido(result_categoria(valor.cat_codigo)),
                            "operacion": reemplazarIndefinido(result_operacion(distribucion.operacion)),
                             "madre": reemplazarIndefinido(result_madre(distribucion.operacion)),
                             "tipo": reemplazarIndefinido(result_tipo(distribucion.operacion)),
                            "idDistribucion": distribucion.idDistribucion,
                             "zonal": contadorElemento(cant_personal,nombre_zonal),
                            "dni": valor.leg_numdoc,
                            "observacion":observacion,
                            "manipulador":reemplazarIndefinido(result_manipulador(valor.leg_numdoc)),
                            "vacunado":reemplazarIndefinido(result_vacunacion(valor.leg_numero)),
                            "dosis":reemplazarIndefinidoDosis(result_dosis(valor.leg_numdoc)),
                            "avatar": reemplazarIndefinido(result_avatar(valor.leg_numdoc)),
                            "salario": basico,
                        })
                    }
                    }
                });




                 console.log(lista);
                this.personal = lista;
                this.triggers.load = false;
                })).then(() => {
                    $('#empresas').DataTable({
                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Ningún dato disponible en esta tabla",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                        "search": {regex:true},
                        dom: 'Bfrtip',
                        
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf'
                        ]
                    });
                });


                $("#mes").html(mes);

                
        },
        refrescarPersonal(seReasigno){
            this.modalAdministrar.open = false;
            this.modalLegales.open = false;
            this.modalObservar.open = false;
            this.modalVacunas.open = false;
            
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

            // this.$nextTick(()=>{
            // })
        },
    },
    mounted() {
        this.obtenerPersonal();
    },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}
.observacion-existente {
    background: #10b759;
    border-radius: 50%;
    padding: 3px 4px;
    padding-top: 4px;
    width: 16px;
    height: 16px;
    position: absolute;
    transform: translate(60px, -20px);
}
</style>