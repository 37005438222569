<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">
              {{ descripcion }}
              </p>
              <button v-if="isEditor('Orientacion')==true"  class="btn btn-success w-25"  @click="nuevoTicket()">Nuevo ticket</button>
              <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Nuevo ticket</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">Selecciona un rango de fechas para filtrar los tickets</h6>
            <form>
                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" v-model='desde'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" v-model='hasta'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button @click.prevent="obtenerOrientaciones(desde, hasta)" class="btn btn-md btn-success w-100">Consultar orientaciones</button>
                    </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>


    <lista-carga v-if="load"></lista-carga>
    <!-- Table -->
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                id="orientaciones"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Status</span></th>
                    <th><span class="text-muted">Fecha/Hora</span></th>
                    <th><span class="text-muted">legajo</span></th>
                    <th><span class="text-muted">DNI</span></th>
                    <th><span class="text-muted">Nombre</span></th>
                    <th><span class="text-muted">Novedad</span></th>
                    <th><span class="text-muted">Acción</span></th>
                    <th><span class="text-muted">Detalle</span></th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="(caso, k) in orientaciones" :key="k">
                        <td>
                            <span
                              class="badge text-uppercase"
                              :class="{
                                  'badge-danger': (caso.status == 'Sin resolver'),
                                  'badge-warning': (caso.status == 'En proceso'),
                                  'badge-success': (caso.status == 'Resuelto')
                              }"
                            >
                              {{caso.status}}
                            </span>
                        </td>
                        <td class="text-center">{{caso.fecha}} <br/> {{caso.hora}}</td>
                        <td class="text-center">{{caso.legajo}}</td>
                        <td>{{caso.dni}}</td>
                        <td>{{caso.nombre}}</td>
                        <td class="text-uppercase">{{caso.novedad}}</td>
                        <td>
                            <select v-if="isEditor('Orientacion')==true" class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="Sin resolver" :selected="caso.status == 'Sin resolver'">Sin resolver</option>
                                <option value="En proceso" :selected="caso.status == 'En proceso'">En proceso</option>
                                <option value="Resuelto" :selected="caso.status == 'Resuelto'">Resuelto</option>
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                <option value="Sin resolver" :selected="caso.status == 'Sin resolver'">Sin resolver</option>
                                <option value="En proceso" :selected="caso.status == 'En proceso'">En proceso</option>
                                <option value="Resuelto" :selected="caso.status == 'Resuelto'">Resuelto</option>
                            </select>


                        </td>
                        <td>
                            <button 
                                class="btn btn-sm btn-primary"
                                @click.prevent="abrirModalDetalle(caso)"
                            >
                            Ver detalle
                            </button>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal-detalle 
    v-if="modalDetalle.open" 
    @cerrar-modal-detalle="refrescarOrientaciones($event)"
    :caso="modalDetalle.caso"
  ></modal-detalle>

  <modal-nuevo-ticket 
    v-if="modalNuevoTicket.open"
    @cerrar-modal-nuevo="refrescarOrientaciones($event)"
    :personal="personal"
  ></modal-nuevo-ticket>
</template>

<script>
import axios from "axios";
import $ from "jquery";

require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");

import firebase from "firebase/app";
import "firebase/database";
import ModalDetalle from './ModalDetalle.vue';
import ModalNuevoTicket from './ModalNuevoTicket.vue';
import ListaCarga from '../ListaCarga.vue';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
export default {
  components: { ModalDetalle, ModalNuevoTicket, ListaCarga },
  name: "Orientacion",
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Orientacion",
    descripcion: "Listado de reclamos del personal, podés listar por fecha, resolver y detallar los inconvenientes",
    orientaciones: [],
    personal: [],
    desde: null,
    hasta: null,
    modalDetalle : {
        open: false,
        caso: null,
    },
    modalNuevoTicket: {
        open: false,
    },
    load: true,

  }),
  methods: {
    isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
    obtenerOrientaciones(desde, hasta){
      this.load = true;
      this.orientaciones = null;
      $('#orientaciones').dataTable().fnDestroy();
      this.$nextTick(()=>{
        this.orientaciones = [];
      })
      

        let url = this.obtenerUrlFire(desde, hasta);

        axios
        .get(url)
        .then(response => {
            var vue = this;
            $.each(response.data, (fireId, item) => {
                let empleado = this.obtenerEmpleadoConDni(item.dni);
                if (empleado!=undefined) {
                    vue.orientaciones.push({
                        dni: empleado.leg_numdoc,
                        fireId: fireId,
                        nombre: empleado.leg_nombre + " " + empleado.leg_apellido,
                        legajo: empleado.leg_numero,
                        fecha: item.fecha,
                        hora: item.hora,
                        id_novedad: item.id_novedad,
                        novedad: item.novedad,
                        observacion: item.observacion,
                        resolucion: item.resolucion,
                        status: item.status,
                    });
                }
                
            })
        })
        .then(()=>{
            this.load = false;
            this.$nextTick(()=>{
              this.refrescarTabla(true);
            });
        })
        .catch(e => {
            console.log(e);
        });
    },
    obtenerUrlFire(desde, hasta){
        if (hasta && desde) {
            return "https://aplicativos.firebaseio.com/orientacion-b.json?orderBy=%22fecha%22&startAt=%22" + desde + "%22&endAt=%22"+ hasta +"%22&print=pretty";   
        }else if (desde) {
            return "https://aplicativos.firebaseio.com/orientacion-b.json?orderBy=%22fecha%22&startAt=%22" + desde + "%22&print=pretty";
        }else {
            return "https://aplicativos.firebaseio.com/orientacion-b.json?print=pretty";
        }
    },
    nuevoTicket(){
        this.modalNuevoTicket.open = true;
    }, 
    obtenerFechaActual(){
        return new Date().toISOString().slice(0, 10);
    },
    async  obtenerPersonal(desde,hasta){
      
 const axiosrequest1 = axios.get('https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty');
 const axiosrequest2 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');

   await axios.all([axiosrequest1, axiosrequest2]).then(
            axios.spread((personal,personal_cat) => {

                var personal_mezcla = Object.assign(personal.data, personal_cat.data)

                 this.personal = personal_mezcla; 
        

         })).then(() => {
            this.obtenerOrientaciones();
        })
        .catch((e)=>{
            console.log(e);
        })


    },
    obtenerEmpleadoConDni(dni){
        return this.personal[dni];
    },
    cambiarStatus(event, caso){
        firebase.database().ref('orientacion-b/' + caso.fireId).update({
            status: event.target.value,
        })
        .then(()=>{
            caso.status = event.target.value;
        })
        .catch(e => {
            console.log(e);
        });


    },
    refrescarTabla(limpiar){
        if (limpiar==true) {
            $('#orientaciones').dataTable().fnDestroy();
        }
        this.$nextTick(()=>{
            $('#orientaciones').dataTable({
                "language": {
                    "decimal": ",",
                    "thousands": ".",
                    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "infoPostFix": "",
                    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                    "loadingRecords": "Cargando...",
                    "lengthMenu": "Mostrar _MENU_ registros",
                    "paginate": {
                        "first": "Primero",
                        "last": "Último",
                        "next": "Siguiente",
                        "previous": "Anterior"
                    },
                    "processing": "Procesando...",
                    "search": "Buscar:",
                    "searchPlaceholder": "Término de búsqueda",
                    "zeroRecords": "No se encontraron resultados",
                    "emptyTable": "Ningún dato disponible en esta tabla",
                    "aria": {
                    "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                    "sortDescending": ": Activar para ordenar la columna de manera descendente"
                    },
                },
                "scrollX": false, 
                "search": {regex:true},
                dom: 'Bfrtip',
                responsive: false,
                nowrap: false,
                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf'
                ]
            });
        })
    },
    refrescarOrientaciones(seReasigno){
        this.modalNuevoTicket.open = false;
        this.modalDetalle.open = false;
        this.obtenerOrientaciones();
    },
    abrirModalDetalle(caso){
        this.modalDetalle.open = true;
        this.modalDetalle.caso = caso;
    },
    
  },
  async mounted() {
    //   this.orientacionesConPersonal();
      this.obtenerPersonal();

    //   this.obtenerOrientacionesAsignarPersonal();
    //   this.obtenerOrientaciones();

    //   setTimeout(() => {
    //       console.log(this.obtenerEmpleadoConDni("33913806"));
          
    //   }, 1000);



    //  axios.get("https://aplicativos.firebaseio.com/legales.json?print=pretty")
    //   .then((response) => {
    //         var vue = this;
    //         $.each(response.data, (dni, item) => {
    //             let caso = item[Object.keys(item)];
    //             vue.legales.push({
    //                 dni: dni,
    //                 fireId: Object.keys(item)[0],
    //                 nombre: caso.nombre,
    //                 statusMensaje: caso.status,
    //                 status: (caso.status == "Resuelto") ? true : false,
    //                 novedad: "Dar de baja en Bejerman",
    //             });
    //         })
    //   })
    //   .then(()=>{
    //         $('#legales').dataTable({
    //         "language": {
    //         "decimal": ",",
    //         "thousands": ".",
    //         "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    //         "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
    //         "infoPostFix": "",
    //         "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    //         "loadingRecords": "Cargando...",
    //         "lengthMenu": "Mostrar _MENU_ registros",
    //         "paginate": {
    //         "first": "Primero",
    //         "last": "Último",
    //         "next": "Siguiente",
    //         "previous": "Anterior"
    //         },
    //         "processing": "Procesando...",
    //         "search": "Buscar:",
    //         "searchPlaceholder": "Término de búsqueda",
    //         "zeroRecords": "No se encontraron resultados",
    //         "emptyTable": "Ningún dato disponible en esta tabla",
    //         "aria": {
    //         "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
    //         "sortDescending": ": Activar para ordenar la columna de manera descendente"
    //         },
    //         },
    //         "scrollX": false, 
    //         "search": {regex:true},
    //         dom: 'Bfrtip',
    //         responsive: false,
    //         nowrap: false,
    //         buttons: [
    //         'copy',
    //         'excel',
    //         'csv',
    //         'pdf'
    //         ]
    //         });
    //   })



  }
}
</script>

<style scoped>
.form-control {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select {
    font-size: 0.7rem;
    padding: .2rem 1.2rem .2rem .2rem;
}

.resolucionSelect {
    min-width: max-content;
}

.badge {
    margin-bottom: 0;
    font-size: 0.6rem;
    font-weight: bold;
}
</style>