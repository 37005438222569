const config = {
  zonas: [
     { codigo: '000001', nombre: 'FS Planta' },
     { codigo: '000765', nombre: 'AMANCO' },
     { codigo: '000804', nombre: 'BALIARDA - SAAVEDRA' },
     { codigo: '000805', nombre: 'BALIARDA - SANTA CRUZ' },
     { codigo: '000073', nombre: 'BIOSIDUS BERNAL' },
     { codigo: '000709', nombre: 'BIOSIDUS - ALMAGRO' },
     { codigo: '000880', nombre: 'CELSUR G RODRIGUEZ' },
     { codigo: '000783', nombre: 'CENTRAL PUERTO 1' },
     { codigo: '000208', nombre: 'DURLOCK (ETERNIT)' },
     { codigo: '002091', nombre: 'LAB. CASASCO PILAR' },
     { codigo: '000743', nombre: 'LAB. CASASCO BOYACA' },
     { codigo: '000039', nombre: 'LAB. CASSARA CARHUE' },
     { codigo: '000019', nombre: 'LEAR PACHECO' },
     { codigo: '000846', nombre: 'PLASTIC OMNIUM SA' },
     { codigo: '000720', nombre: 'RK MOLDEADOS' },
     { codigo: '000849', nombre: 'WHIRLPOOL' },
   ],
   operaciones: {
     'empresa-000558': 'MARKETING/PLANTA',
     'empresa-000553': 'FACTURACIÓN/PLANTA',
     'empresa-000559': 'COSTOS/PLANTA',
     'empresa-001016': 'NUTRICIÓN/PLANTA',
     'empresa-000555': 'RRHH/PLANTA',
     'empresa-000562': 'DIRECCIÓN/PLANTA',
     'empresa-000659': 'GERENTE/ZONAL',
     'empresa-000066': 'JEFE COCINA/PLANTA',
     'empresa-000564': 'SELECCIÓN/PLANTA',
     'empresa-000170': 'LOGISTICA/PLANTA',
     'empresa-000544': 'CALIDAD/PLANTA',
     'empresa-000563': 'LEGALES/PLANTA',
     'empresa-000554': 'COORDINACIÓN/PL,TA',
     'empresa-000556': 'COMPRAS/PLANTA',
     'empresa-000561': 'PRE-ELABORADOS/PLANTA',
     'empresa-000168': 'ARMADO/PLANTA',
     'empresa-000552': 'TESORERÍA/PLANTA',
     'empresa-999999': 'ASIGNACIÓN TEMPORAL',
     'empresa-999991': 'SECTOR ZONALES',
     'empresa-900101': 'VOLANTES',
     'empresa-900102': 'PRE-LEGALES',
     'empresa-999992': 'COCINA GOURMET/PLANTA',
     'empresa-999993': 'EATY/PLANTA',
     'empresa-999994': 'PAGO A PROVEEDORES/PLANTA',
     'empresa-999995': 'COCINA/PLANTA',
     'empresa-999996': 'CONTADURÍA/PLANTA',
     'empresa-999997': 'CALIDAD/OPERACIONES',
     'empresa-999998': 'MANTENIMIENTO/PLANTA',
     'empresa-100111': 'CAPACITACIÓN/OPERACIONES',
     'empresa-100112': 'SISTEMAS/PLANTA',
     'empresa-010017': 'CONTABILIDAD'
   },
}

export default config