<template>
	<div class="page-content">
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			<li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
			</ol>
		</nav>
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
				<h6 class="card-title">{{breadcrumbB}}</h6>
				<p class="card-description"> {{descripcion}}</p>
				</div>  
			</div>
			</div>
		</div> 
		<lista-carga v-if="loading"></lista-carga>
		<!-- Template content  -->
		<div class="row" v-else>
			<div class="col-md-12 grid-margin stretch-card">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table id="empresas" class="table">
							<thead>
								<tr>
									<th>Cod</th>
									
									<th>Nombre</th>
									
									<th>Personal</th>
									
									<th>Acción</th>

									<th>Responsable</th>

									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="operacion in operaciones" v-bind:key="operacion.id">
									<td>{{operacion.id}}</td>
									
									<td>{{operacion.name}}</td>
									
									<td>{{operacion.personal}}</td>
								
									<td>
										
										<button v-if="isEditor('Empresas')==true" type="button" class="btn btn-primary btn-xs" @click="administrarOperacionId(operacion.id_empresa, operacion.name,'personal')">Administrar</button>
										<button v-else type="button" class="btn btn-secondary btn-xs" disabled>Administrar</button>
										
									
									</td>

									<td>
										<button v-if="isEditor('Empresas')==true" class="btn btn-warning btn-xs ml-2" @click="administrarOperacionId(operacion.id_empresa, operacion.name, 'zonal')">Responsable</button>
										<button v-else class="btn btn-secondary btn-xs ml-2" disabled>Responsable</button>
									</td>
									<td>{{operacion.zonal}}</td>

								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			</div>
		</div>
		<!-- <div class="row">
			<div>
				<Select2 v-model="myValue" :options="empleados" :settings="{ settingOption: value, settingOption: value, width:'100%', theme: 'classic' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
				<h4>Value: {{ myValue }}</h4>
			</div>
		</div> -->
        <modal-administrar
            v-if="modalAdministrar" 
            :empresa="modalOperacionId"
			:nombreEmpresa="modalOperacionNombre"
            @cerrar-modal-asignar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalAdministrar=false"
        >
        </modal-administrar>
        <modal-zonal
		v-if="modalZonal"
		:empresa="modalOperacionId"
		:nombreEmpresa="modalOperacionNombre"
		@cerrar-modal-zonal="refrescarEmpresas($event)"
		></modal-zonal>

		<modal-observar
			v-if="modalObservar" 
            :idEmpresa="modalOperacionId"
			:nombreEmpresa="modalOperacionNombre"
            @cerrar-modal-observar="refrescarEmpresas($event)"
			v-on:keyup.enter="modalObservar=false"
		></modal-observar>
	</div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalObservar from './ModalObservar.vue';
import ListaCarga from "../ListaCarga";
import ModalZonal from './ModalZonal.vue';

require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    components: { 
        ModalAdministrar, 
        ListaCarga ,
		ModalObservar,
		ModalZonal,

    },    
	name: 'Empresas',
   
    data() {
        return {
			breadcrumbA: 'Inicio',
            breadcrumbB: 'Sectores',
            descripcion: 'En este modulo usted podra visualizar y administrar personal en cada sector de Planta',
			loading: true,
            operaciones: [],
			modalOperacionId: null,
			modalOperacionNombre: null,
			modalAdministrar: false,
			modalObservar: false,
			modalZonal: false
        }
    },

    methods: {
    	 isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
      	},
		administrarOperacionId(id, nombre,modal){
			console.log(id)
			console.log(nombre)
			console.log(modal)
			this.modalOperacionId = id;
			this.modalOperacionNombre = nombre;
			setTimeout(() => {
				if (modal == 'personal') {
					this.modalAdministrar = true;
				} else if (modal=='zonal') {
					this.modalZonal = true;
				}
			}, 200);
		},
		observarOperacionId(id, nombre){
			this.modalOperacionId = id;
			this.modalOperacionNombre = nombre;
			setTimeout(() => {
				this.modalObservar = true;
			}, 200);
		},
		async obtenerInformacionEmpresas(){
			
			this.loading = true;

			var lunes = new Date();
			lunes.setDate(lunes.getDate() - 5);
			var mma = lunes.getMonth() + 1;
			var mesma = mma < 10 ? "0" + mma : mma;
			var diama = lunes.getDate();
			diama = diama < 10 ? "0" + diama : diama;
			var pasado = lunes.getFullYear() + "-" + mesma + "-" + diama;

			var fecha1 = new Date(pasado);
			var dias1 = (fecha1.getDay() - 1) * -1;

			fecha1.setDate(fecha1.getDate() + dias1);
			var mex = fecha1.getMonth() + 1;
			var mesex = mex < 10 ? "0" + mex : mex;
			var diaex = fecha1.getDate();
			diaex = diaex < 10 ? "0" + diaex : diaex;
			var lunespasadob = fecha1.getFullYear() + "-" + mesex + "-" + diaex;
			var dia1 = fecha1.getFullYear() + "-" + mesex + "-" + diaex;

			var fecha2 = new Date(lunespasadob);
			fecha2.setDate(fecha2.getDate() + 5);
			var mmax = fecha2.getMonth() + 1;
			var mesmax = mmax < 10 ? "0" + mmax : mmax;
			var diamax = fecha2.getDate();
			diamax = diamax < 10 ? "0" + diamax : diamax;
			var dia2 = fecha2.getFullYear() + "-" + mesmax + "-" + diamax;



		const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');
		const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/zonal.json?print=pretty');
		const axiosrequest3 = axios.get('https://consumos.foodservice.com.ar/api/totalesServicioRango?desde='+dia1+'&hasta='+dia2);
		const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
        const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/observacion.json?print=pretty');
        




		await axios.all([axiosrequest1, axiosrequest2, axiosrequest3, axiosrequest4, axiosrequest5]).then(axios.spread((operaciones, zonales, consumos, distribucion, observaciones) => {

			var lista = [];

			var zonal = "-";
			var personal = 0;
		
			var observacion = false;
			var legales = false;

		$.each(operaciones.data, function(clave, valor) {

		
		

			var key_distribucion = Object.keys(distribucion.data).filter(key => key == valor.id_empresa);

			if (distribucion.data[key_distribucion[0]] != null) {
				personal = Object.keys(distribucion.data[key_distribucion[0]]).length;
			}else{
				personal=0;
			}

			

			var key_zonal = Object.keys(zonales.data).filter(key => key == valor.id_empresa);

			var result_zonal = key_zonal.reduce((r, k) => r.concat(zonales.data[k]), []);
			if (key_zonal.length!=0) {
				zonal = result_zonal[0].nombre;
			}else{
				zonal = '-';
			}

				
		
			
				

					lista.push({
						"id": valor.id,
						"id_empresa": valor.id_empresa,
						"name": valor.operacion,
						"personal": personal,
						"zonal": zonal,
	                });

                


            });

            this.operaciones = lista;


		})).then(() => {
			this.loading = false;
			this.$nextTick(()=>{
				$('#empresas').DataTable({
					"language": {
						"decimal": ",",
						"thousands": ".",
						"info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
						"infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
						"infoPostFix": "",
						"infoFiltered": "(filtrado de un total de _MAX_ registros)",
						"loadingRecords": "Cargando...",
						"lengthMenu": "Mostrar _MENU_ registros",
						"paginate": {
							"first": "Primero",
							"last": "Último",
							"next": "Siguiente",
							"previous": "Anterior"
						},
						"processing": "Procesando...",
						"search": "Buscar:",
						"searchPlaceholder": "Término de búsqueda",
						"zeroRecords": "No se encontraron resultados",
						"emptyTable": "Ningún dato disponible en esta tabla",
						"aria": {
							"sortAscending":  ": Activar para ordenar la columna de manera ascendente",
							"sortDescending": ": Activar para ordenar la columna de manera descendente"
						},
					},
					"scrollX": false, 
					"search": {regex:true},
					dom: 'Bfrtip',
					responsive: true,
					nowrap: false,
					buttons: [
						'copy',
						'excel',
						'csv',
						'pdf'
					]
				});
			})
		});

		},
		refrescarEmpresas(seReasigno){
            this.modalAdministrar = false;
            this.modalZonal = false;
            this.modalObservar = false;
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerInformacionEmpresas();
            }
        },
    },
	async mounted() {
		this.obtenerInformacionEmpresas();		
    },
}

</script>

<style scoped>
.observacion-existente {
	background: #10b759;
	border-radius: 50%;
	padding: 3px 4px;
	padding-top: 4px;
	width: 16px;
	height: 16px;
	position: absolute;
	transform: translate(60px, -20px);
}
</style>