export var app = {
  nombre: "healthy",
  dbUrl: 'https://aplicativos.firebaseio.com/'
};

export var api = {
  posts: `${app.dbUrl}/posts.json`,
  preguntas: `${app.dbUrl}/preguntas.json`,
  perfiles: `${app.dbUrl}/perfiles.json`,
  platos: "https://fs-recetario-default-rtdb.firebaseio.com/recetas.json",
  etiquetasNutricionales: "https://aplicativos.firebaseio.com//etiquetas/nutricionales.json",
  operacionesConsumo: "https://consumos.foodservice.com.ar/api/empresas",
  noticias: `https://aplicativos.firebaseio.com//noticias_slide.json`,
  anuncios: `https://aplicativos.firebaseio.com//anuncios.json`,
  tag_condicion: "https://fs-recetario-default-rtdb.firebaseio.com/tag_condicion.json",
  tipo_entradas: "https://fs-recetario-default-rtdb.firebaseio.com/tipo_entradas.json"
};

// En Platos.vue no se estta usando la api por url same origin

/**
 * Devuelve una url que apunta a un nodo o a un grupo de ellos
 * @param {*} api es el nombre de la api a utilizar, generalmente nombres plurales devuelven grupos de un nodo
 * @param {*} parametro el id o dato que se busca, o a traves del que se ordenan los registros
 * @returns 
 */
export function prefabQ(api, parametro) {
    /*
    * Esto de debe reformular mucho. El parámetro api debería estar inmerso en el primer parámetro de la url, el grupo que se busca.
    * Se puede agregar un tipo de ordenamiento (orderBy).
    * Se puede agregar otro parametro de busqueda.
    */
  switch (api) {
    case "post":
      return `https://aplicativos.firebaseio.com/posts/${parametro}.json`;
    case "pregunta":
      return `https://aplicativos.firebaseio.com/preguntas/${parametro}.json`;
    case "plato":
      return `https://fs-recetario-default-rtdb.firebaseio.com/recetas/${parametro}.json`;
    case "etiqueta":
      return `https://aplicativos.firebaseio.com/etiquetas/nutricionales/${parametro}.json`;
    case "postsPorCategoria":
        return `https://aplicativos.firebaseio.com/posts.json?orderBy="categoria"&equalTo="${parametro}"&print=pretty`;
    case "perfil":
          return `${app.dbUrl}/perfiles/${parametro}.json`;
          default:
      return "ninguna api encontrada";
  }
}

export var firebaseConfig = {
  // Desarrollo
  apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
    authDomain: "cantina-89c6a.firebaseapp.com",
    databaseURL: "https://aplicativos.firebaseio.com",
    projectId: "cantina-89c6a",
    storageBucket: "cantina-89c6a.appspot.com",
    messagingSenderId: "353062217145",
    appId: "1:353062217145:web:8849a49a1ec52077"

  // Produccion
  // apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
  // authDomain: "cantina-89c6a.firebaseapp.com",
  // databaseURL: "https://cantina-89c6a.firebaseio.com",
  // projectId: "cantina-89c6a",
  // storageBucket: "cantina-89c6a.appspot.com",
  // messagingSenderId: "353062217145",
  // appId: "1:353062217145:web:8849a49a1ec52077"

  
    // Recetario
    // apiKey: "AIzaSyBIdDKcZ5YHXzwG3UOjRiDRzV7vFymuMmo",
    // authDomain: "fs-recetario.firebaseapp.com",
    // projectId: "fs-recetario",
    // storageBucket: "fs-recetario.appspot.com",
    // messagingSenderId: "847772736340",
    // appId: "1:847772736340:web:5222a18850cd6e1d9bc6c1"
  
  
};

export var tableConfig = {
  
};
